import onDOMContentLoaded from '../../../base/util/dom/onDOMContentLoaded';

onDOMContentLoaded(() => {
    const root = document.querySelector('.firb__account-register');
    if (!root) {
        return;
    }

    const deliveryNotDifferentInput = <HTMLInputElement>root.querySelector('#registration_form_deliverySameAsInvoice');
    const deliveryDifferentInput = <HTMLInputElement>root.querySelector('#registration_form_differentDeliveryAddress');
    const deliveryAddressContainer = <HTMLElement>root.querySelector('.firb__kud04-register__delivery-address');

    if ((!deliveryNotDifferentInput && !deliveryDifferentInput) || !deliveryAddressContainer) {
        return;
    }

    const deliveryAddressContainerInputFields = <NodeListOf<HTMLInputElement>>deliveryAddressContainer.querySelectorAll('.as__input__control');
    const deliveryAddressContainerSelectFields = <NodeListOf<HTMLSelectElement>>deliveryAddressContainer.querySelectorAll('.as__select__control');
    const deliveryAddressErrorMessages = <NodeListOf<HTMLSelectElement>>deliveryAddressContainer.querySelectorAll('.as__info-error');

    /**
     * Required attributes are set during rendering.
     * If we disable, the delivery address, we need to remove the rendered required attributes.
     * If we activate it again, BE Validation kicks in, so no need to set the required attributes again.
     * @param activate boolean
     */
    const toggleFieldAttributes = (activate:boolean) => {
        if(!activate) {
            deliveryAddressContainerInputFields.forEach((input) => {
                input.required = false;
                input.disabled = true;
                input.classList.remove('as__field-error');
            });
            deliveryAddressContainerSelectFields.forEach((select) => {
                select.required = false;
                select.disabled = true;
                select.classList.remove('as__field-error');
            });
            deliveryAddressErrorMessages.forEach((error) => {
                error.remove();
            });

            return;
        }

        deliveryAddressContainerInputFields.forEach((input) => {
            input.disabled = false;
        });
        deliveryAddressContainerSelectFields.forEach((select) => {
            select.disabled = false;
        });
    }

    deliveryNotDifferentInput?.addEventListener('change', () => {
        toggleFieldAttributes(!deliveryNotDifferentInput.checked);
    });

    deliveryDifferentInput?.addEventListener('change', () => {
        toggleFieldAttributes(deliveryDifferentInput.checked);
    });

    toggleFieldAttributes(deliveryDifferentInput?.checked || !deliveryNotDifferentInput?.checked);
});