import onDOMContentLoaded from "../util/dom/onDOMContentLoaded";
import {DeepPartial} from "../util/deepPartial";
import assignOptions from "../util/assignOptions";
import {createIcon} from "../icon/icon";

export type AlertType = 'success'|'info'|'warning'|'error';

export interface AlertOptions {
    selectors: {
        alert: string,
        dismiss: string,
        alertContainer: string,
    },

    // 0 = do not disappear
    disappearInMs: number
}

export interface ShowAlertOptions {
    dismissable: boolean,
    disappearInMs: number,
    alertContainer: string
}

export default class Alert {
    public static readonly DefaultOptions : AlertOptions = {
        selectors: {
            alert: '.as__alert',
            dismiss: '.as__alert__dismiss',
            alertContainer: '[data-alert-container="%"]'
        },

        disappearInMs: 15_000
    };

    protected readonly element : HTMLElement;
    protected readonly options : AlertOptions;

    public constructor(element : HTMLElement, options : DeepPartial<AlertOptions> = {}) {
        this.element = element;
        this.options = assignOptions(options, Alert.DefaultOptions);

        const dismissButton = element.querySelector(this.options.selectors.dismiss);

        if(dismissButton instanceof HTMLButtonElement) {
            dismissButton.addEventListener('click', this.dismiss.bind(this), false);
        }

        if(this.options.disappearInMs > 0) {
            setTimeout(this.dismiss.bind(this), this.options.disappearInMs);
        }
    }

    dismiss() {
        if(!this.element.parentNode) {
            return;
        }

        this.element.parentNode.removeChild(this.element);
    }

    public static success(message : string, showOptions : DeepPartial<ShowAlertOptions> = {}): Alert {
        return this.showAlert('success', message, showOptions);
    }

    public static info(message : string, showOptions : DeepPartial<ShowAlertOptions> = {}): Alert {
        return this.showAlert('info', message, showOptions);
    }

    public static warning(message : string, showOptions : DeepPartial<ShowAlertOptions> = {}): Alert {
        return this.showAlert('warning', message, showOptions);
    }

    public static error(message : string, showOptions : DeepPartial<ShowAlertOptions> = {}): Alert {
        return this.showAlert('error', message, showOptions);
    }

    private static showAlert(type : AlertType, message : string, showOptions : DeepPartial<ShowAlertOptions> = {}): Alert {
        const options : ShowAlertOptions = assignOptions(showOptions, {
            dismissable: true,
            disappearInMs: Alert.DefaultOptions.disappearInMs,
            alertContainer: 'global'
        });

        const element = document.createElement('div');
        element.classList.add('as__alert', 'as__alert--' + type);

        const messageElement = document.createElement('div');
        messageElement.classList.add('as__alert__message');
        messageElement.innerHTML = message;

        const iconWrapper = document.createElement('div');
        iconWrapper.classList.add('as__alert__icon');
        iconWrapper.appendChild(createIcon(type));

        element.appendChild(iconWrapper);
        element.appendChild(messageElement);

        if(options.dismissable) {
            const dismissButton = document.createElement('button');

            dismissButton.classList.add('as__alert__dismiss');
            dismissButton.type = 'button';
            dismissButton.setAttribute('aria-hidden', 'true');
            element.appendChild(dismissButton);
        }

        const container = document.querySelector(
            Alert.DefaultOptions.selectors.alertContainer.replace('%', options.alertContainer)
        );

        if(container instanceof HTMLElement) {
            container.appendChild(element);
        }

        return new Alert(element, {
            disappearInMs: options.disappearInMs
        });
    }
}

onDOMContentLoaded(() => {
    document.querySelectorAll(Alert.DefaultOptions.selectors.alert).forEach(alertElement => {
        if(!(alertElement instanceof HTMLElement)) {
            return;
        }

        new Alert(alertElement, {
            disappearInMs: 0
        });
    });
});
