import BaseSlider from "../../components/base-slider/base-slider";
import onDOMContentLoaded from "../../base/util/dom/onDOMContentLoaded";
import isEditMode from "../../base/util/dom/isEditMode";

onDOMContentLoaded(() => {
    document.querySelectorAll('.firb__m06-product-teaser-slider').forEach(element => {
        if(!(element instanceof HTMLElement)) {
            return;
        }

        new BaseSlider(element, {
            selectors: {
                container: '.firb__m06-product-teaser-slider__container',
                items: '.firb__m06-product-teaser-slider__item'
            },
            grid: {
                adaptContainerHeight: isEditMode ? false : 'global',
                adaptItemHeight: !isEditMode,
                resetOnResize: 'page'
            },
            arrows: {
                enabled: true,

                selectors: {
                    prev: '.firb__m06-product-teaser-slider__arrow--prev',
                    next: '.firb__m06-product-teaser-slider__arrow--next'
                },
                classes: {
                    hidden: 'firb__m06-product-teaser-slider__arrow--hidden'
                },
                stepType: 'page'
            },
            mouseWheel: {
                enabled: !isEditMode,
                stepType: 'page'
            },
            swipe: {
                enabled: !isEditMode,
                stepType: 'page'
            },
            nav: {
                enabled: false
            },
            infinite: {
                enabled: false
            }
        });
    });
});
