import BaseSlider from "../../../components/base-slider/base-slider";
import onDOMContentLoaded from "../../../base/util/dom/onDOMContentLoaded";

onDOMContentLoaded(() => {
    document.querySelectorAll('.firb__product-cross-selling-carousel__slider').forEach((carouselElement) => {
        if(!(carouselElement instanceof HTMLElement)) {
            return;
        }

        const carousel = new BaseSlider(carouselElement, {
            selectors: {
                container: '.as__carousel__container',
                items: '.as__carousel__item'
            },
            core: {
                itemPosition: 'start',
            },
            grid: {
                adaptItemHeight: true,
                resetOnResize: 'page'
            },
            arrows: {
                selectors: {
                    prev: '.as__carousel__arrow--prev',
                    next: '.as__carousel__arrow--next'
                },
                stepType: 'item',
            },
            swipe: {
                stepType: 'item'
            },
            infinite: {
                enabled: true 
            }
        });
    });
});
