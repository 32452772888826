import editmode from "./editmode";

export const showErrorInfo = (element: HTMLElement) => {
    const wrapper = <HTMLElement>element.closest('div');
    const info = wrapper.querySelector('.as__info-error');
    if (info) {
        info.classList.remove('as__hide');
    }
};

export const hideErrorInfo = (element: HTMLElement) => {
    element.classList.remove('as__field-error');
    const wrapper = <HTMLElement>element.closest('div');
    const info = wrapper.querySelector('.as__info-error');

    if (editmode) {
        return;
    }

    if (info) {
        info.classList.add('as__hide');
    }
};

export const hideFormErrorInfos = (form: HTMLFormElement) => {
    Array.from(form.elements).map((element) => {
        const target = <HTMLElement>element;
        hideErrorInfo(target);
    });
};

export const addResetFormErrorsEventListener = (form: HTMLFormElement) => {
    if (!form) {
        return;
    }
    Array.from(form.elements).map((element) => {
        element.addEventListener('focus', (e) => {
            const target = <HTMLElement>e.target;
            hideErrorInfo(target);
        });
    });
};