export interface ResponseHandler<R> {
    (response : Response) : Promise<R>;
}

export function SimpleResponseHandler(response : Response): Promise<Response> {
    return Promise.resolve(response);
}

export function DOMResponseHandler(response : Response): Promise<DocumentFragment> {
    return response.text().then(html => {
        const div = document.createElement("div");
        const fragment = document.createDocumentFragment();
        div.innerHTML = html;

        while(div.firstChild) {
            fragment.appendChild(div.firstChild);
        }

        return fragment;
    });
}

export async function JSONResponseHandler<R>(response : Response): Promise<R> {
    const jsonResponse = await response.json();

    if(jsonResponse.error) {
        return Promise.reject(new Error(jsonResponse.error));
    }

    return jsonResponse.data;
}

export function BlobResponseHandler(response : Response): Promise<Blob> {
    return response.blob();
}

export class ApiRequest<Q extends Record<string, any>, B extends BodyInit, R> {
    private readonly apiUrl : URL;
    private readonly options : RequestInit;
    private readonly responseHandler : ResponseHandler<R>;
    private abortController : AbortController|null;

    constructor(apiUrl : string, method : string, responseHandler : ResponseHandler<R> = JSONResponseHandler) {
        this.apiUrl = new URL(apiUrl, window.location.toString());
        this.options = {
            method: method,
            headers: {
                'Accept-Language': document.documentElement.lang,
                'X-FIRB-Ajax': '1'
            }
        };
        this.responseHandler = responseHandler;
        this.abortController = null;
    }

    getUrl(): string {
        return this.apiUrl.toString();
    }

    setQueryParams(values : Partial<Q>): this {
        for(let key in values) {
            if(!values.hasOwnProperty(key)) {
                continue;
            }

            this.setQueryParam(key, values[key]);
        }

        return this;
    }

    setQueryParam(key : keyof Q, value : any): this {
        if(!value) {
            Array.from(this.apiUrl.searchParams.keys()).forEach(queryKey => {
                if(queryKey === key || queryKey.startsWith(key + "[")) {
                    this.apiUrl.searchParams.delete(queryKey);
                }
            });
        } else if(Array.isArray(value)) {
            value.forEach(item => {
                this.setQueryParam(key + "[]", item);
            });
        } else if(typeof value === "object") {
            for(let objKey in value) {
                if(!value.hasOwnProperty(objKey)) {
                    continue;
                }

                this.setQueryParam(key + "[" + objKey + "]", value[objKey]);
            }
        } else {
            this.apiUrl.searchParams.set(String(key), String(value));
        }

        return this;
    }

    setHeader(name : string, value ?: string): this {
        if(value) {
            (this.options.headers as Record<string, string>)[name] = value;
        } else {
            delete (this.options.headers as Record<string, string>)[name];
        }

        return this;
    }

    setBody(body : B|undefined): this {
        this.options.body = body;
        return this;
    }

    cancel(): this {
        if(this.abortController) {
            this.abortController.abort();
        }

        return this;
    }

    send(): Promise<R> {
        this.cancel();
        const options = {...this.options};

        if("AbortController" in window) {
            this.abortController = new AbortController();
            options.signal = this.abortController.signal;
        }

        return fetch(this.apiUrl.toString(), options).then(async response => {
            if(response.status < 200 || response.status >= 400) {
                const data = await response.json();

                if (data.error) {
                    return Promise.reject(new Error(data.error));
                }

                return Promise.reject(new Error("Request not ok!"));
            }

            return this.responseHandler(response);
        });
    }
}

export class JsonApiRequest<Q extends Record<string, any>, R> extends ApiRequest<Q, string, R> {
    setJson(body : any): this {
        return this.setBody(JSON.stringify(body))
            .setHeader("Content-Type", "application/json");
    }
}

export class FormApiRequest<Q extends Record<string, any>, R> extends ApiRequest<Q, FormData, R> {
    protected formData : FormData;

    constructor(apiUrl : string, method : string, responseHandler : ResponseHandler<R> = JSONResponseHandler) {
        super(apiUrl, method, responseHandler);

        this.formData = new FormData();
        this.setBody(this.formData);
    }

    setFormData(formData : FormData): this {
        this.formData = formData;
        this.setBody(this.formData);
        return this;
    }

    setData(name : string, value : string | Blob, fileName ?: string): this {
        if(fileName) {
            this.formData.set(name, value, fileName);
        } else {
            this.formData.set(name, value);
        }

        return this;
    }
}