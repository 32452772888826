import BaseSlider, {BaseSliderOptions} from "../../components/base-slider/base-slider";
import onDOMContentLoaded from "../../base/util/dom/onDOMContentLoaded";
import isEditMode from "../../base/util/dom/isEditMode";
import BaseSliderItem from "../../components/base-slider/base-slider-item";

onDOMContentLoaded(() => {
    document.querySelectorAll('.firb__m1-hero').forEach(element => {
        if(!(element instanceof HTMLElement)) {
            return;
        }

        new BaseSlider(element, {
            selectors: {
                container: '.firb__m1-hero__container',
                items: '.firb__m1-hero__item'
            },
            grid: {
                adaptContainerHeight: 'global',
                adaptItemHeight: true,
                resetOnResize: 'page',
                itemHeightCalculator: (item: BaseSliderItem, options: BaseSliderOptions) => {
                    const height = BaseSlider.DefaultOptions.grid.itemHeightCalculator(item, options);
                    if(options.grid.adaptItemHeight) {
                        /**
                         * see: pecorino/source/modules/m01__hero/config.less
                         * @m01__hero__background-cube__overflow
                         * @m01__hero__background-cube__overflow--xl
                         * @m01__hero__background-cube__overflow--lg
                         * @m01__hero__background-cube__overflow--md
                         */
                        let additionalHeight = 10;
                        if(window.matchMedia('(min-width: 1440px)').matches) {
                            additionalHeight = 50;
                        } else if (window.matchMedia('(min-width: 1024px)').matches) {
                            additionalHeight = 35;
                        } else if (window.matchMedia('(min-width: 768px)').matches) {
                            additionalHeight = 25;
                        }

                        return height + additionalHeight;
                    }
                    return height;
                }
            },
            arrows: {
                enabled: true,

                selectors: {
                    prev: '.firb__m1-hero__arrow--prev',
                    next: '.firb__m1-hero__arrow--next'
                },
                classes: {
                    hidden: 'firb__m1-hero__arrow--hidden'
                },
                stepType: 'page'
            },
            mouseWheel: {
                enabled: !isEditMode,
                stepType: 'page'
            },
            swipe: {
                enabled: !isEditMode,
                stepType: 'page'
            },
            nav: {
                enabled: false
            },
            infinite: {
                enabled: false
            }
        });
    });
});