import onDOMContentLoaded from '../../base/util/dom/onDOMContentLoaded';

import '../../base/form/password-input';
import {addResetFormErrorsEventListener} from '../../base/util/formErrorsHelpers';

const SUBMIT_EVENT = 'register-submit';

const hideElement = (element: Element, hide: boolean) => {
    if (hide) {
        element.classList.add('as__hide');
    } else {
        element.classList.remove('as__hide');
    }
};

const showErrorInfo = (element: HTMLElement) => {
    const wrapper = <HTMLElement>element.closest('div');
    const info = wrapper.querySelector('.as__info-error');
    if (info) {
        info.classList.remove('as__hide');
    }
};

onDOMContentLoaded(() => {
    const root = document.querySelector('.firb__kud04-register');
    if (!root) {
        return;
    }
    const form = <HTMLFormElement>root.querySelector('form');
    const memberShipClass = `firb__select__${form.id}-membership`;
    const memberShipNumberClass = `firb__input__${form.id}-membershipNumber`;
    const passwordClass = `firb__input__${form.id}-password-first`;
    const passwordRepeatClass = `firb__input__${form.id}-password-second`;
    const ajaxRegisterFormClass = 'firb__kud04-register__form--ajax';
    const accountTypeGuestId = 'firb__kud04-register__account-type-guest';
    const deliveryAddressDifferentSelector = '#registration_form_differentDeliveryAddress, #checkoutAddress_differentDeliveryAddress';
    const deliveryAddressContainerClass = 'firb__kud04-register__delivery-address';
    let memberShipSelect: HTMLSelectElement | null = null;

    const memberShipContainer = <HTMLElement>root.querySelector(`.${memberShipClass}`);
    if (memberShipContainer) {
        memberShipSelect = <HTMLSelectElement>memberShipContainer.querySelector('select');
        if (memberShipSelect) {
            const memberShipNumberContainer = <HTMLElement>root.querySelector(`.${memberShipNumberClass}`);
            memberShipSelect.addEventListener('change', (e) => {
                hideElement(memberShipNumberContainer, memberShipSelect?.value == '');
            });
            hideElement(memberShipNumberContainer, memberShipSelect.value == '');
        }
    }

    const accountTypeGuestCheckbox = <HTMLInputElement>root.querySelector(`#${accountTypeGuestId}`);
    if (accountTypeGuestCheckbox) {
        accountTypeGuestCheckbox.addEventListener('change', (e) => {
            const hideGuestElementClassNames = [
                memberShipClass,
                memberShipNumberClass,
                passwordClass,
                passwordRepeatClass,
            ];

            hideGuestElementClassNames.map((className) => {
                Array.from(root.querySelectorAll(`.${className}`)).map((element) => {
                    hideElement(element, accountTypeGuestCheckbox.checked);
                });
            });

            const memberShipNumberContainer = <HTMLElement>root.querySelector(`.${memberShipNumberClass}`);
            if (!accountTypeGuestCheckbox.checked && memberShipSelect?.value == '') {
                hideElement(memberShipNumberContainer, true);
            }
        });
    }

    const deliveryAddressDifferentCheckbox = <HTMLInputElement>root.querySelector(deliveryAddressDifferentSelector);
    if (deliveryAddressDifferentCheckbox !== null) {
        deliveryAddressDifferentCheckbox.addEventListener('change', (e) => {
            const container = <HTMLElement>root.querySelector(`.${deliveryAddressContainerClass}`);
            hideElement(container, !deliveryAddressDifferentCheckbox.checked);
        });
    }

    addResetFormErrorsEventListener(form);
});

export {
    SUBMIT_EVENT
};
