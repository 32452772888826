import onDOMContentLoaded from "../../base/util/dom/onDOMContentLoaded";
onDOMContentLoaded(() => {
    let form = document.querySelector('.firb__cart-overview-page form');

    if (!(form instanceof HTMLFormElement)) {
        return;
    }

    form.querySelectorAll('.firb__cart-tile__price .as__select select').forEach((select) => {
        if (!(select instanceof HTMLSelectElement)) {
            return;
        }

        select.addEventListener('change', () => {
            if (!(form instanceof HTMLFormElement)) {
                return;
            }

            form.submit()
        });
    });
});
