/**
 * Modal class
 * Toggles modal-container on trigger
 */
export default class Modal {

    public static readonly Selectors = {
        Modal: '.as__modal:not(.as__modal--no-init)',
        ModalWrapper: '.as__modal__wrapper',
        ModalTrigger: '[data-modal-trigger]',
        CloseBtn: '.as__modal__close-btn, .as__modal__close-trigger',
        Content: '.as__model__content',
    }

    public static readonly States = {
        ModalOpen: 'as__modal--opened'
    }

    public static readonly Attributes = {
        ModalId: 'data-modal-id',
        ModalTrigger: 'data-modal-trigger',
        ModalTriggerPreventDefault: 'data-modal-trigger-prevent-default',
        EnableCloseOnOutsideClick: 'data-enable-outside-click',
        EnableCloseButton: 'data-enable-close'
    }

    protected readonly domElements : {
        modal: Element,
        modalWrapper: Element|null,
        closeBtns: Array<Element>,
        modalFilteredTriggers: Array<Element>
    };
    protected readonly settings : {modalId: string|null, isCloseButtonEnabled: boolean, isCloseOnclickOutsideModalEnabled: boolean};

    constructor(element : Element) {
        this.settings = {
            modalId: element.getAttribute(Modal.Attributes.ModalId),
            isCloseButtonEnabled: !(element.getAttribute(Modal.Attributes.EnableCloseButton) === 'false'),
            isCloseOnclickOutsideModalEnabled: !(element.getAttribute(Modal.Attributes.EnableCloseOnOutsideClick) === 'false'),
        };

        this.domElements = {
            modal: element,
            modalWrapper: element.querySelector(Modal.Selectors.ModalWrapper),
            closeBtns: Array.from(element.querySelectorAll(Modal.Selectors.CloseBtn)),
            modalFilteredTriggers: Array.from(document.querySelectorAll(Modal.Selectors.ModalTrigger))
                .filter(element =>  element.getAttribute(Modal.Attributes.ModalTrigger) === this.settings.modalId)
        };

        this.closeOnClickOutsideHandler = this.closeOnClickOutsideHandler.bind(this);

        this.addCloseBtnEvents();
        this.addModalTriggerEvents();
    }

    protected addCloseBtnEvents() {
        if(this.settings.isCloseButtonEnabled){
            this.domElements.closeBtns
                .forEach(closeBtn => closeBtn.addEventListener('click', this.closeModal.bind(this), false));
        }
    }

    protected addModalTriggerEvents() {
        if(this.domElements.modalFilteredTriggers) {
            this.domElements.modalFilteredTriggers.forEach((item) => {
                item.addEventListener('click', event => {
                    const shouldPreventDefault = item.hasAttribute(Modal.Attributes.ModalTriggerPreventDefault);

                    if(shouldPreventDefault) {
                        event.preventDefault();
                    }

                    this.showModal();
                })
            });
        }

    }

    protected closeModal() {
        this.domElements.modal.classList.remove(Modal.States.ModalOpen);
        if(this.settings.isCloseOnclickOutsideModalEnabled) {
            document.removeEventListener('click', this.closeOnClickOutsideHandler, false);
        }
    }

    protected showModal() : void {
        this.domElements.modal.classList.add(Modal.States.ModalOpen);
        if(this.settings.isCloseOnclickOutsideModalEnabled){
            setTimeout(() => {
                document.addEventListener('click', this.closeOnClickOutsideHandler, false);
            });
        }
    }

    protected findParentModal(element: HTMLElement|null) {
        do {
            if (element === this.domElements.modalWrapper) {
                return true;
            }
        } while( element && (element = element.parentElement));

        return false;
    }

    protected closeOnClickOutsideHandler(event: MouseEvent) {
        if(!(event.target instanceof HTMLElement)){
            return;
        }

        if (!this.findParentModal(event.target)) {
            this.closeModal();
        }
    }
}

document.addEventListener('DOMContentLoaded', () => {
    let modals = document.querySelectorAll(Modal.Selectors.Modal);
    modals.forEach(element => {
        new Modal(element);
    });
});


