import Alert, {AlertType} from "../../base/alert/alert";
import {ApiRequest, DOMResponseHandler, SimpleResponseHandler} from "../../base/util/Api";
import Log from "../../base/util/Log";
import Modal from "../../components/modal/modal";

class Flyout extends Modal {
    public static readonly Classes = {
        Modal: 'firb__cart-flyout__modal',
        Content: 'firb__cart-flyout__content',
        Trash: 'firb__cart-flyout__trash',
        Loading: 'firb__cart-flyout__modal--loading'
    };
    public static readonly AlertContainer = 'cart-flyout';
    public static readonly WidgetUrl = '/cart/flyout';

    protected widgetUrl : string;
    protected currentRequest : ApiRequest<any, any, DocumentFragment>|null;

    constructor() {
        let element = document.querySelector('.' + Flyout.Classes.Modal);

        if(!(element instanceof HTMLElement)) {
            element = document.createElement('div');
        }

        super(element);

        this.widgetUrl = document.baseURI.replace(/\/$/, '') + Flyout.WidgetUrl;
        this.currentRequest = null;
        this.initCartContent(element);
    }

    show(): this {
        this.showModal();
        return this;
    }

    hide(): this {
        this.closeModal();
        return this;
    }

    reload(): Promise<void> {
        const request = this.currentRequest = new ApiRequest(this.widgetUrl, 'GET', DOMResponseHandler);
        this.toggleLoadingAnimation(true);

        return request.send().then(fragment => {
            if(this.currentRequest !== request) {
                return;
            }

            const oldContent = this.domElements.modal.querySelector('.' + Flyout.Classes.Content);
            const newContent = fragment.querySelector('.' + Flyout.Classes.Content);

            if(!oldContent || !newContent || !oldContent.parentElement) {
                return Promise.reject(new Error('Something went wrong, please reload the page.'));
            }

            oldContent.parentElement.replaceChild(newContent, oldContent);

            this.initCartContent(newContent);
        }).catch(reason => {
            this.alert('error', reason);
            return Promise.reject(reason);
        });
    }

    alert(type : AlertType, message : any): this {
        if(type === 'error') {
            Log.error('Error in flyout:', message);
        }

        if(message instanceof Error) {
            message = message.message;
        } else {
            message = String(message);
        }

        Alert[type](message, {
            dismissable: false,
            alertContainer: Flyout.AlertContainer
        });
        return this;
    }

    protected toggleLoadingAnimation(shown : boolean|undefined = undefined): void {
        this.domElements.modal.classList.toggle(Flyout.Classes.Loading, shown);
    }

    protected onRemoveFromCart(href : string): void {
        this.toggleLoadingAnimation(true);
        (new ApiRequest(href, 'GET', SimpleResponseHandler))
            .send()
            .then(
                () => this.alert('info', 'Produkt wurde vom Warenkorb entfernt.').reload(),
                error => this.alert('error', error)
            );
    }

    protected initCartContent(element : Element): void {
        element.querySelectorAll('.' + Flyout.Classes.Trash).forEach(trashLink => {
            if(!(trashLink instanceof HTMLAnchorElement)) {
                return;
            }

            trashLink.addEventListener('click', (event : MouseEvent) => {
                event.preventDefault();
                this.onRemoveFromCart(trashLink.href);
            }, false);
        });
        this.toggleLoadingAnimation(false);
    }
}

export default new Flyout();