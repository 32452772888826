import onDOMContentLoaded from "../util/dom/onDOMContentLoaded";
import AbstractComponent from "../util/AbstractComponent";
import {DeepPartial} from "../util/deepPartial";
import assignOptions from "../util/assignOptions";
import {FormApiRequest, SimpleResponseHandler} from "../util/Api";
import Alert, {ShowAlertOptions} from "../alert/alert";
import "./password-input";

export interface FormElements {
    container: HTMLFormElement
}

export interface FormStates {
    hidden: string
}

export interface FormOptions {
    ajax: boolean,
    method: string,
    action: string,
    messageSuccess: string,
    messageFailure: string,
    showOnSuccessSelectors: {number: string},
    hideOnSuccessSelectors: {number: string},
    showAlertOptions: DeepPartial<ShowAlertOptions>,
    resetOnSuccess: boolean,
    states: DeepPartial<FormStates>
}

export default class Form extends AbstractComponent<FormOptions, FormElements> {
    constructor(container : HTMLFormElement, options : DeepPartial<FormOptions> = {}) {
        super(container, assignOptions(options, {
            ajax: container.hasAttribute('data-form-ajax'),
            method: container.getAttribute('method') || 'GET',
            action: container.getAttribute('action') as string,
            messageSuccess: container.querySelector<HTMLInputElement>('input[name="form-message-success"]')?.value
                || container.getAttribute('data-form-message-success')
                || 'Success',
            messageFailure: container.querySelector<HTMLInputElement>('input[name="form-message-failure"]')?.value
                || container.getAttribute('data-form-message-failure')
                || 'Unknown Failure',
            showOnSuccessSelectors: JSON.parse(container.getAttribute('data-form-show-on-success-selectors') || '{}'),
            hideOnSuccessSelectors: JSON.parse(container.getAttribute('data-form-hide-on-success-selectors') || '{}'),
            showAlertOptions: {
                dismissable: true,
                disappearInMs: 10000,
                alertContainer: container.getAttribute('data-form-alert-container') || 'global'
            },
            resetOnSuccess: true,
            states: {
                hidden: 'as__hide'
            }
        }));

        this.elements.container.addEventListener('submit', this.onSubmit.bind(this), false);
    }

    getData(): FormData {
        return new FormData(this.elements.container);
    }

    protected onSubmit(event : Event): void {
        if(!this.options.ajax) {
            return;
        }

        // need to read data before event.preventDefault otherwise FormData is empty sometimes?
        const data = this.getData();
        const request = new FormApiRequest(this.options.action, this.options.method, SimpleResponseHandler);

        event.preventDefault();
        this.setInputsDisabled(true);

        request.setFormData(data)
            .send()
            .then(() => {
                this.showSuccess();
                this.setInputsDisabled(false, this.options.resetOnSuccess);
            }, () => {
                this.showError();
                this.setInputsDisabled(false);
            });
    }

    protected showError(): void {
        Alert.error(this.options.messageFailure, this.options.showAlertOptions)
    }

    protected showSuccess(): void {
        let key: keyof {number: string};

        for (key in this.options.hideOnSuccessSelectors) {
            document.querySelectorAll(this.options.hideOnSuccessSelectors[key]).forEach(element => {
                if(!(element instanceof HTMLElement)) {
                    return;
                }

                if(!this.options.states.hidden) {
                    return;
                }

                element.classList.add(this.options.states.hidden);
            })
        }

        for (key in this.options.showOnSuccessSelectors) {
            document.querySelectorAll(this.options.showOnSuccessSelectors[key]).forEach(element => {
                if(!(element instanceof HTMLElement)) {
                    return;
                }

                if(!this.options.states.hidden) {
                    return;
                }

                element.classList.remove(this.options.states.hidden);
            })
        }

        Alert.success(this.options.messageSuccess, this.options.showAlertOptions);
    }

    protected setInputsDisabled(flag : boolean, clearInputs : boolean = false): void {
        const formElements = this.elements.container.elements;

        for(let i = 0; i < formElements.length; ++i) {
            const formElement = formElements[i];

            if(
                !(formElement instanceof HTMLInputElement)
                && !(formElement instanceof HTMLTextAreaElement)
                && !(formElement instanceof HTMLSelectElement)
            ) {
                continue;
            }

            if(flag) {
                formElement.setAttribute('disabled', 'disabled');
            } else {
                formElement.removeAttribute('disabled');
            }

            if(clearInputs) {
                if(
                    formElement instanceof HTMLInputElement
                    && (formElement.type === 'radio' || formElement.type === 'checkbox')
                ) {
                    formElement.checked = false;
                } else {
                    formElement.value = '';
                }
            }
        }
    }
}

onDOMContentLoaded(() => {
    document.querySelectorAll('.as__form').forEach(element => {
        if(!(element instanceof HTMLFormElement)) {
            return;
        }

        new Form(element);
    })
})