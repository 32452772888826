import BaseSlider from "../../../components/base-slider/base-slider";
import onDOMContentLoaded from "../../../base/util/dom/onDOMContentLoaded";

onDOMContentLoaded(() => {
    document.querySelectorAll('.firb__product-media-carousel__slider').forEach((carouselElement) => {
        if(!(carouselElement instanceof HTMLElement)) {
            return;
        }

        new BaseSlider(carouselElement, {
            selectors: {
                container: '.as__carousel__container',
                items: '.as__carousel__item'
            },
            core: {
                itemPosition: 'center',
            },
            grid: {
                adaptItemHeight: true,
            },
            nav: {
                enabled: true,
                selectors: {
                    items: '.as__slider-nav__item',
                },
                classes: {
                    active: 'as__slider-nav__item--active',
                }
            },
            arrows: {
                selectors: {
                    prev: '.as__carousel__arrow--prev',
                    next: '.as__carousel__arrow--next'
                },
                stepType: 'item',
            },
            swipe: false,
            infinite: {
                enabled: true
            }
        });
    });
});
