// region IMPORTS
// polyfills
import 'core-js/features/dom-collections';
import 'classlist-polyfill';

// vendors
import * as Hammer from 'hammerjs';
import {throttle} from 'throttle-debounce';

// base utilities
import assignOptions from '../../base/util/assignOptions';
import {DeepPartial} from "../../base/util/deepPartial";
import sign from "../../base/util/sign";
import EventTarget, {createCustomEvent} from '../../base/util/dom/EventTarget';
import isRtl from "../../base/util/dom/isRtl";
import passiveEvent from '../../base/util/dom/passiveEvent';
import rearrangeDom from "../../base/util/dom/rearrangeDom";

// sub modules
import BaseSliderItem from './base-slider-item';
// endregion IMPORTS

// region INTERFACES

// this option value defines if a slider transition should transition one 'item' at a time, or the complete visible 'page'
export type BaseSliderStepType = 'page' | 'item';

export interface BaseSliderOptions {
    // basic selector names
    selectors: {
        // css selector for the slider item container, default: ".as__slider__container"
        container: string,
        // css selector for slider items, default: ".as__slider__item"
        items: string
    },

    // core options, which are required for all features
    core: {
        // duration of the transition animation between two slides in milliseconds, default: 800
        animationDuration: number,
        // animation type for the transition between two slides, default: 'fade'
        // * 'fade' transitions using css opacity
        // * 'slide' transitions using 'transform: translate'
        animationType: 'fade' | 'slide',
        // css transition value for slider items, default value depends on 'core.animationType' and 'core.animationDuration':
        // * for 'fade': "opacity " + core.animationDuration + "ms ease"
        // * for 'slide': "transform " + core.animationDuration + "ms ease"
        animationTransition: string,
        // Positioning of the (currently active) slider item inside the viewport, default: 'start' (left)
        itemPosition: 'center' | 'start' | 'end',
        // (performance optimization) change the resize listener function throttle delay (in milliseconds), default: 100
        resizeThrottleDelay: number,
        // adds basic slider stylings when true (i.e. 'overflow' and 'position'), default: true
        addDefaultStyles: boolean
    },

    // all options for the grid-feature, this feature is always enabled.
    // if you do not want to use it, simply dont assign grid classes 'as__col-' to the slider items
    grid: {
        // adapt the outer container height to the slider items height, default: 'global'
        // * false: do not adapt container height
        // * 'global': adapts container height on resize, for the largest slider item of the slider
        // * 'page': adapts container height on resize and slide transition for the largest slider item
        //           in the currently visible page
        adaptContainerHeight: false | 'global' | 'page',
        // adapt the slider items height to the height of the container, default: false
        // note that all elements inside the slider items need to have fixed heights in order for this to work correctly!
        adaptItemHeight: boolean,
        // when using adaptItemHeight=true, you will probably need to overwrite this, to correctly calculate the
        // slider items inner height
        itemHeightCalculator: (item : BaseSliderItem, options : BaseSliderOptions) => number,

        // breakpoint configuration, default values match the default grid breakpoints
        breakpoints: {
            // xs: 0
            // sm: 576
            // md: 768
            // lg: 1024
            // xl: 1440
            // xxl: 1920
            [name : string]: number
        },

        // number of grid columns per row, default is '12' to match our grid
        gridColumns: number,
        // function to read grid column size of slider item for a given breakpoint
        // see 'BaseSlider.getGridColumnSizeDefault' for default value and example implementation
        getColumnSize: ((element : HTMLElement, breakpoint : string) => number),
        // reset slider position on resize (default: false)
        // * 'start' = move to start of slider
        // * 'page' = refresh current page
        resetOnResize: 'start' | 'page' | false
    },

    // swipe feature (touch navigation)
    // can be disabled by setting either 'swipe: false' or 'swipe.enabled: false'
    swipe: false | {
        // enable or disable feature, default: true
        enabled: boolean,
        // configure if swipe navigation moves per 'item' or per 'page', default: 'page'
        stepType: BaseSliderStepType,
        // deplay for ghost click prevention (see http://web.archive.org/web/20140326101523/https://developers.google.com/mobile/articles/fast_buttons ) in milliseconds, default: 2000
        antiGhostDelay: number,
        // maximal distance for ghost click prevention (see http://web.archive.org/web/20140326101523/https://developers.google.com/mobile/articles/fast_buttons ), default: 25
        antiGhostDistance: number,
        // minimum swipe velocity to trigger a swipe navigation in pixel per frame, default: 0.8
        minSwipeVelocity: number,
        // minimum swipe distance to trigger a swipe navigation in pixel, default: 40
        minSwipeWidth: number,
        // animation when reaching the start/end of the slider, default is a resisting pull animation:
        // `(distance, direction) => Math.round(Math.sqrt(distance) * direction)`
        endAnimation: false | ((distance : number, direction : number) => number),
        // disable dragging (prevents dragging images while swiping), default: true
        disableDragging: boolean
    },

    // mousewheel/trackpad/vertical-scroll navigation feature
    // can be disabled by setting either 'mouseWheel: false' or 'mouseWheel.enabled: false'
    mouseWheel: false | {
        // enable this feature, default: true
        enabled: boolean,
        // configure if this navigation moves per 'item' or per 'page', default: 'page'
        stepType: BaseSliderStepType
        // (performance optimization) delay to throttle mouseWheel events in milliseconds, default: 150
        throttleDelay: number,
        // timeout to pause between navigation steps (since there are no start/stop events) in milliseconds, default: 1000
        timeoutDelay: number
    },

    // arrow navigation feature
    // can be disabled by setting either 'arrows: false' or 'arrows.enabled: false'
    arrows: false | {
        // enable this feature, default: true
        enabled: boolean,
        // configure if a click on an arrow moves an 'item' or the current 'page', default: 'page'
        stepType: BaseSliderStepType,
        // adapt the arrow height to an element inside the slider items, e.g. centered inside an slider item image
        // * false: disable the arrow height adapting (default)
        // * string: an selector for elements inside the slider items, to adapt the height
        adaptHeightTo: false | string,

        // configure the css selectors for prev & next arrow button
        selectors: {
            // default: '.as__slider__arrow--prev'
            prev: string,
            // default: '.as__slider__arrow--next'
            next: string
        },

        // configure the css class which is applied to the arrow buttons when they should be invisible
        classes: {
            // default: 'as__slider__arrow--hidden'
            hidden: string
        }
    },

    // menu/dots/list navigation feature
    // can be disabled by setting either 'nav: false' or 'nav.enabled: false'
    nav: false | {
        // enable this feature, default: true
        enabled: boolean,

        // css selector for the nav/dot/list items.
        // you should generate exactly the same amount if nav items as slider items (e.g. in typo3/fluid),
        // so the javascript can match these
        selectors: {
            // default: '.as__slider-nav__item'
            items: string
        },

        // css classes for the nav items when they are active or hidden
        classes: {
            // default: '.as__slider-nav__item--active'
            active: string,
            // default: '.as__slider-nav__item--hidden'
            hidden: string
        }
    },

    // infinite rotation feature (carousel)
    // can be disabled by setting either 'infinite: false' or 'infinite.enabled: false'
    infinite: false | {
        // enable or disable this feature, default: false 
        enabled: boolean,
        // enable this feature when only a single page of items is shown, default: false 
        enabledWhenSinglePage: boolean,

        // css class that is applied to cloned slider items, default: 'as__slider__infinite-clone'
        clonedSlideClass: string
    },

    // automatic rotation feature
    // can be enabled by setting 'autoRotate.enabled: true' or 'autoRotate.enabled: 'reverse''
    autoRotate: false | {
        // enable or disable this feature, default: false
        // * false: automatic rotation disabled
        // * true: automatic rotation enabled (left to right in dir="ltr", right to left in dir="rtl")
        // * 'reverse': automatic rotation enabled (right to left in dir="ltr", left to right in dir="rtl")
        enabled: boolean | 'reverse',
        // automatically move to the next 'item' or next 'page' per step, default: 'item'
        stepType: BaseSliderStepType,
        // delay in milliseconds between automatic rotations, default: 8000
        delay: number,
        // should reset autoRotate timer when mouse is hovering the slider, default: true
        stopOnHover: boolean,
        // should reset autoRotate timer when slider is clicked, default: true
        stopOnClick: boolean
    }
}

export interface BaseSliderDOM {
    root: HTMLElement,
    elements: {
        [selector: string]: HTMLElement
    },
    lists: {
        [selector: string]: NodeListOf<HTMLElement>
    }
}

export interface BaseSliderPage {
    items : Array<BaseSliderItem>,
    startIndex : number,
    width: number
}

export interface BaseSliderState {
    index: number,
    pageIndex: number,
    disabled: boolean,
    inAnimation: boolean,
    width: number,
    height: number,
    breakpoint: string
}

export interface BaseSliderInitializedEvent extends Event {
    type: 'initialized',
    target: BaseSlider
}

export interface BaseSliderInitializedItemEvent extends Event {
    type: 'initialized-item',
    target: BaseSlider,
    item: BaseSliderItem
}

export interface BaseSliderResizeEvent extends Event {
    type: 'resize',
    target: BaseSlider,
    innerWidth: number,
    innerHeight: number
}

export interface BaseSliderGoEvent extends Event {
    type: 'go',
    target: BaseSlider,
    relative: number,
    instantTransition: boolean,
    index: number
}

export interface BaseSliderUpdateEvent extends Event {
    type: 'update' | 'before-update',
    target: BaseSlider,
    forceUpdate: boolean
}

export interface BaseSliderClickEvent extends Event {
    type: 'click',
    target: BaseSlider,
    elementTarget: HTMLElement,
    item: BaseSliderItem,
    itemIndex: number,
    slideIndex: number
}

export interface BaseSliderPanEvent extends Event {
    type: 'pan',
    target: BaseSlider,
    hammer: HammerInput,
    deltaX: number
}

export interface BaseSliderItemRegisteredEventListener {
    target: any,
    type: string,
    listener: any,
    options: AddEventListenerOptions|boolean
}
// endregion INTERFACES

export default class BaseSlider extends EventTarget {
    public static readonly DefaultOptions : BaseSliderOptions = {
        selectors: {
            container: '.as__slider__container',
            items: '.as__slider__item'
        },

        core: {
            animationDuration: 800,
            animationType: 'slide',
            animationTransition: '', // see constructor for initialization
            itemPosition: 'start',
            resizeThrottleDelay: 100,
            addDefaultStyles: true
        },

        grid: {
            adaptContainerHeight: 'global',
            adaptItemHeight: false,
            itemHeightCalculator: BaseSlider.defaultItemHeightCalculator,

            breakpoints: {
                xs: 0,
                sm: 576,
                md: 768,
                lg: 1024,
                xl: 1440,
                xxl: 1920
            },

            gridColumns: 12,
            getColumnSize: BaseSlider.getGridColumnSizeDefault,
            resetOnResize: false
        },
        swipe: {
            enabled: true,
            stepType: 'page',
            antiGhostDelay: 2000,
            antiGhostDistance: 25,
            minSwipeVelocity: 0.8,
            minSwipeWidth: 40,
            endAnimation: (distance, direction) => Math.round(Math.sqrt(distance) * direction),
            disableDragging: true
        },
        mouseWheel: {
            enabled: true,
            stepType: 'page',
            throttleDelay: 150,
            timeoutDelay: 1000
        },
        arrows: {
            enabled: true,
            stepType: 'page',
            adaptHeightTo: false,
            selectors: {
                prev: '.as__slider__arrow--prev',
                next: '.as__slider__arrow--next'
            },
            classes: {
                hidden: 'as__slider__arrow--hidden'
            }
        },
        nav: {
            enabled: true,
            selectors: {
                items: '.as__slider-nav__item'
            },
            classes: {
                active: 'as__slider-nav__item--active',
                hidden: 'as__slider-nav__item--hidden'
            }
        },
        infinite: {
            enabled: false,
            enabledWhenSinglePage: false,
            clonedSlideClass: 'as__slider__infinite-clone'
        },
        autoRotate: {
            enabled: false,
            stepType: 'item',
            delay: 8000,
            stopOnHover: true,
            stopOnClick: true
        }
    };

    protected readonly options : BaseSliderOptions;
    protected readonly dom : BaseSliderDOM;
    protected readonly state : BaseSliderState;
    protected readonly items : Array<BaseSliderItem>;
    protected readonly clonedItemsPrepended : Array<BaseSliderItem>;
    protected readonly clonedItemsAppended : Array<BaseSliderItem>;
    protected readonly pages : Array<BaseSliderPage>;
    protected readonly registeredEventListeners : BaseSliderItemRegisteredEventListener[];

    public constructor(element : HTMLElement, options : DeepPartial<BaseSliderOptions> = {}) {
        super();

        this.options = assignOptions(options, BaseSlider.DefaultOptions);

        // initializes default animationTransition depending on animationType
        if(!this.options.core.animationTransition) {
            this.options.core.animationTransition =
                (this.options.core.animationType === 'fade' ? 'opacity ' : 'transform ')
                + this.options.core.animationDuration + 'ms ease';
        }

        // just initialize dom with root element, all other elements can be found using
        // this.getDOMElement(selector) or this.getDOMList(selector) they are cached in
        // this.dom.elements and this.dom.lists on initial use
        this.dom = {
            root: element,
            elements: {},
            lists: {}
        };

        this.state = {
            index: 0,
            pageIndex: -1, // initially set by updatePages()
            disabled: false,
            inAnimation: false,
            width: 0, // initially set by onGridResize()
            height: 0, // initially set by onGridResize()
            breakpoint: 'xs' // initially set by onGridResize()
        };

        this.items = [];
        this.pages = [];
        this.clonedItemsPrepended = [];
        this.clonedItemsAppended = [];
        this.registeredEventListeners = [];

        this.getDOMElement(this.options.selectors.container)
            .querySelectorAll(this.options.selectors.items)
            .forEach((itemElement, index) => {
                if(!(itemElement instanceof HTMLElement)) {
                    return;
                }

                this.items.push(new BaseSliderItem(itemElement, String(index), this));
            });
        
        this.onClick = this.onClick.bind(this);

        this.initCore();
        this.initGrid();
        this.initSwipe();
        this.initMouseWheel();
        this.initArrows();
        this.initNav();
        this.initInfinite();
        this.initAutoRotate();

        setTimeout(() => this.dispatchEvent(createCustomEvent('initialized')));
    }

// region PUBLIC_API
    public goTo(index : number, instantTransition : boolean = false, includingClonedItems : boolean = false) : this {
        if(!includingClonedItems) {
            index = index + this.clonedItemsPrepended.length;
        }

        return this.go(index - this.state.index, instantTransition);
    }

    public goToPage(index : number, instantTransition : boolean = false) : this {
        return this.goPage(index - this.state.pageIndex, instantTransition);
    }

    public go(relative : number, instantTransition : boolean = false) : this {
        let newIndex = this.state.index;

        if(this.options.infinite && this.options.infinite.enabled && this.isInfiniteActive) {
            newIndex = Math.max((this.items.length + this.state.index + relative) % this.items.length, 0);
        } else if(relative < 0 && this.hasPrev()) {
            newIndex = Math.max(this.state.index + relative, 0);
        } else if(relative > 0 && this.hasNext()) {
            newIndex = Math.min(this.state.index + relative, this.getLastItemIndex());
        }

        if(newIndex === this.state.index) {
            return this;
        }

        if(!this.dispatchEvent(createCustomEvent('before-go', {
            relative: relative,
            instantTransition: instantTransition,
            index: newIndex
        }))) {
            return this;
        }

        if(!instantTransition) {
            this.state.inAnimation = true;
        }

        this.state.index = newIndex;

        this.updatePositions(0, instantTransition, () => {
            this.state.inAnimation = false;
            this.updatePages();

            this.dispatchEvent(createCustomEvent('go', {
                relative: relative,
                instantTransition: instantTransition,
                index: newIndex
            }));
        });

        return this;
    }

    public goPage(relative : number, instantTransition : boolean = false) : this {
        let targetIndex = 0;
        let relativeItems = 0;

        if(this.options.infinite && this.options.infinite.enabled && this.isInfiniteActive) {
            targetIndex = Math.max((this.pages.length + this.state.pageIndex + relative) % this.pages.length, 0);
        } else if(relative < 0 && this.hasPrev('page')) {
            targetIndex = Math.max(this.state.pageIndex + relative, 0);
        } else if(relative > 0 && this.hasNext('page')) {
            targetIndex = Math.min(this.state.pageIndex + relative, this.pages.length - 1);
        }

        if(targetIndex > this.state.pageIndex) {
            for(let i = this.state.pageIndex; i < targetIndex; ++i) {
                relativeItems += this.pages[i].items.length;
            }
        } else if(targetIndex < this.state.pageIndex) {
            for(let i = targetIndex; i < this.state.pageIndex; ++i) {
                relativeItems -= this.pages[i].items.length;
            }
        }

        return this.go(relativeItems, instantTransition);
    }

    public goPrev(instantTransition : boolean = false) : this {
        return this.go(-1, instantTransition);
    }

    public goNext(instantTransition : boolean = false) : this {
        return this.go(1, instantTransition);
    }

    public goPrevPage(instantTransition : boolean = false) : this {
        return this.goPage(-1, instantTransition);
    }

    public goNextPage(instantTransition : boolean = false) : this {
        return this.goPage(1, instantTransition);
    }

    public hasPrev(stepType ?: BaseSliderStepType) : boolean {
        if(this.options.infinite && this.options.infinite.enabled && this.isInfiniteActive) {
            return true;
        }

        if(stepType === 'page') {
            return this.state.pageIndex > 0;
        }

        return this.state.index > 0;
    }

    public hasNext(stepType ?: BaseSliderStepType) : boolean {
        if(this.options.infinite && this.options.infinite.enabled && this.isInfiniteActive) {
            return true;
        }

        if(stepType === 'page') {
            return this.state.pageIndex < this.pages.length - 1;
        }

        return this.state.index < this.getLastItemIndex();
    }

    public getIndex(includeClonedItems : boolean = false) : number {
        if(includeClonedItems) {
            return this.state.index;
        }

        return this.state.index - this.clonedItemsPrepended.length;
    }

    public count(includeClonedItems : boolean = false) : number {
        if(includeClonedItems) {
            return this.items.length;
        }

        return this.items.length - this.clonedItemsPrepended.length - this.clonedItemsAppended.length;
    }

    public getDOMElement(selector : string = 'root') : HTMLElement {
        if(selector === 'root') {
            return this.dom.root;
        }

        let element = this.dom.elements[selector];

        if(element) {
            return element;
        }

        let queriedElement = this.dom.root.querySelector(selector);

        if(!(queriedElement instanceof HTMLElement)) {
            throw new Error('could not find element for selector "' + selector + '"');
        }

        this.dom.elements[selector] = queriedElement;
        return queriedElement;
    }

    public getDOMList(selector : string) : NodeListOf<HTMLElement> {
        let nodeList = this.dom.lists[selector];

        if(!nodeList) {
            nodeList = this.dom.root.querySelectorAll(selector);
            this.dom.lists[selector] = nodeList;
        }

        return nodeList;
    }

    public getOptions() : Readonly<BaseSliderOptions> {
        return this.options;
    }

    public getState() : Readonly<BaseSliderState> {
        return this.state;
    }

    public getItems() : ReadonlyArray<BaseSliderItem> {
        return this.items;
    }

    public forceUpdate() : this {
        this.updatePages(true);
        this.updatePositions(0, true);
        return this;
    }

    public destroy() {
        this.dispatchEvent(createCustomEvent('destroy'));

        this.registeredEventListeners.forEach((registeredEventListener) => {
            registeredEventListener.target.removeEventListener(
                registeredEventListener.type,
                registeredEventListener.listener,
                registeredEventListener.options
            );
        });

        super.destroy();
    }
// endregion PUBLIC_API

// region GLOBAL
    protected initCore() {
        this.registerEventListener(this.getDOMElement(), 'click', this.onClick, false);

        if(this.options.core.addDefaultStyles) {
            const container = this.getDOMElement(this.options.selectors.container);

            container.style.overflow = 'hidden';
            container.style.position = 'relative';
        }

        const resizeHandler = throttle(this.options.core.resizeThrottleDelay, () => {
            // use setTimeout to delay listeners, so a possible removal of scrollbars will not screw with the layout
            setTimeout(() => {
                this.dispatchEvent(createCustomEvent('resize', {
                    innerWidth: window.innerWidth,
                    innerHeight: window.innerHeight
                }));
            });
        });

        this.registerEventListener(window, 'resize', resizeHandler, passiveEvent);

        // reevaluate sizes when images are loaded
        this.getDOMList('img').forEach((image) => {
            this.registerEventListener(image, 'load', resizeHandler, passiveEvent);
        });

        // initial resize, also triggers forceUpdate which initializes with .updatePages
        // use setTimeout to delay listeners, so a possible removal of scrollbars will not screw with the layout
        this.addEventListener('initialized', resizeHandler);
    }

    protected onClick(event : MouseEvent) {
        if(!(event.target instanceof Node)) {
            return;
        }

        for(let i = 0; i < this.items.length; ++i) {
            const item = this.items[i];

            if(item.getElement().contains(event.target)) {
                if (!this.dispatchEvent(createCustomEvent('click', {
                    elementTarget: event.target,
                    item: item,
                    itemIndex: i,
                    slideIndex: i - this.clonedItemsPrepended.length
                }))) {
                    event.preventDefault();
                    return false;
                }
            }
        }
    }

    protected updatePages(forceUpdate : boolean = false) {
        if(!this.dispatchEvent(createCustomEvent('before-update', { forceUpdate }))) {
            return;
        }

        let pageIndex = 0;
        let currentPage : BaseSliderPage = BaseSlider.createPage(this.state.index);

        this.pages.splice(0, this.pages.length, currentPage);

        for(let itemIndex = this.state.index; itemIndex < this.items.length; ++itemIndex) {
            if(currentPage.width >= 1) {
                currentPage = BaseSlider.createPage(itemIndex);
                this.pages.push(currentPage);
            }

            const item = this.items[itemIndex];
            currentPage.width += item.updateItemForPages(forceUpdate);
            currentPage.items.push(item);
        }

        currentPage = BaseSlider.createPage();

        for(let itemIndex = this.state.index - 1; itemIndex >= 0; --itemIndex) {
            if(currentPage.width >= 1) {
                currentPage.startIndex = itemIndex + 1;

                this.pages.unshift(currentPage);
                pageIndex++;

                currentPage = BaseSlider.createPage();
            }

            const item = this.items[itemIndex];
            currentPage.width += item.updateItemForPages(forceUpdate);
            currentPage.items.unshift(item);
        }

        if(currentPage.items.length > 0) {
            this.pages.unshift(currentPage);
            pageIndex++;
        }

        this.state.pageIndex = pageIndex;

        this.dispatchEvent(createCustomEvent('update', { forceUpdate }));
    }

    protected updatePositions(deltaX : number, instantTransition : boolean, callback ?: () => void) {
        const itemPosition = this.options.core.itemPosition;
        let callbackCount = 0;
        let transitionCallback : undefined | (() => void) = undefined;
        let initialItemOffset = deltaX;

        if(itemPosition === 'center') {
            if(
                (
                    !this.options.infinite
                    || !this.options.infinite.enabled
                    || !this.options.infinite.enabledWhenSinglePage
                ) && this.pages.length === 1
            ) {
                // when less than a full page is shown, try to center all items equally
                const page = this.pages[0];

                if(page.width < 1) {
                    initialItemOffset += (this.state.width * (1 - page.width)) / 2;
                }
            } else {
                initialItemOffset += (this.state.width - this.items[this.state.index].getWidth()) / 2;
            }
        } else if((itemPosition === 'end' && !isRtl()) || (itemPosition === 'start' && isRtl())) { // right
            initialItemOffset += this.state.width - this.items[this.state.index].getWidth();
        }

        if(!instantTransition && callback) {
            transitionCallback = () => {
                callbackCount++;

                if(callbackCount >= this.items.length) {
                    callback();
                }
            };
        }

        let currentOffset = initialItemOffset;

        for(let itemIndex = this.state.index; itemIndex < this.items.length; ++itemIndex) {
            const item = this.items[itemIndex];

            item.setTransition(!instantTransition);
            item.setPosition(currentOffset, transitionCallback);

            currentOffset += item.getWidth();
        }

        currentOffset = initialItemOffset;

        for(let itemIndex = this.state.index - 1; itemIndex >= 0; --itemIndex) {
            const item = this.items[itemIndex];

            currentOffset -= item.getWidth();

            item.setTransition(!instantTransition);
            item.setPosition(currentOffset, transitionCallback);
        }

        if(instantTransition && callback) {
            callback();
        }
    }

    protected registerEventListener(
        target : any,
        type : string,
        listener : any,
        options : AddEventListenerOptions|boolean
    ): void {
        this.registeredEventListeners.push({ target, type, listener, options });
        target.addEventListener(type, listener, options);
    }

    protected unregisterEventListener(
        target : any,
        type : string,
        listener : any,
        options : AddEventListenerOptions|boolean
    ): void {
        for(let i = 0; i < this.registeredEventListeners.length; ++i) {
            const registeredListener = this.registeredEventListeners[i];

            if(
                registeredListener.target === target
                && registeredListener.type === type
                && registeredListener.listener === listener
                && registeredListener.options === options
            ) {
                this.registeredEventListeners.splice(i, 1);
                break;
            }
        }

        target.removeEventListener(type, listener, options);
    }
// endregion GLOBAL

// region GRID
    protected initGrid() {
        this.addEventListener('resize', this.onGridResize.bind(this));
        this.addEventListener('go', this.updateGridAdaptiveHeight.bind(this));
    }

    protected updateGridAdaptiveHeight() {
        const adaptItemHeight = this.options.grid.adaptItemHeight;
        let adaptContainerHeight = this.options.grid.adaptContainerHeight;

        if(!adaptContainerHeight && adaptItemHeight) {
            adaptContainerHeight = 'global';
            window.console && console.warn && console.warn(
                'BaseSlider options.grid.adaptContainerHeight was false, using fallback to "global"'
                + ', because options.grid.adaptItemHeight was set! '
                + 'To fix this, set options.grid.adaptContainerHeight to "global" or "page"'
            );
        }

        if(!adaptContainerHeight && !adaptItemHeight) {
            return;
        }

        const container = this.getDOMElement(this.options.selectors.container);
        const currentPage = this.pages[this.state.pageIndex];
        let containerHeight = 0;

        for (let i = 0; i < this.items.length; ++i) {
            const item = this.items[i];
            let itemHeight = this.options.grid.itemHeightCalculator(item, this.options);

            if(adaptContainerHeight === 'global' || (adaptContainerHeight === 'page' && currentPage.items.indexOf(item) !== -1)) {
                containerHeight = Math.max(containerHeight, itemHeight);
            }
        }

        if(this.state.height !== containerHeight) {
            this.state.height = containerHeight;
            container.style.height = containerHeight + 'px';

            if (adaptItemHeight) {
                for (let i = 0; i < this.items.length; ++i) {
                    const item = this.items[i];
                    item.setHeight(containerHeight);
                }
            }
        }

        this.dispatchEvent(createCustomEvent('adapt-height'));
    }

    protected onGridResize(event : BaseSliderResizeEvent) {
        const containerClientRect = this.getDOMElement(this.options.selectors.container).getBoundingClientRect();
        const oldPageIndex = this.state.pageIndex;
        const oldPageCount = this.pages.length;
        const oldBreakpoint = this.state.breakpoint;
        this.state.breakpoint = this.readBreakpoint(event.innerWidth);
        this.state.width = containerClientRect.width;


        if(oldBreakpoint !== this.state.breakpoint && this.options.grid.resetOnResize) {
            this.goTo(0, true);
        }

        this.forceUpdate();
        this.updateGridAdaptiveHeight();

        if(oldBreakpoint !== this.state.breakpoint && this.options.grid.resetOnResize === 'page') {
            const newPageIndex = Math.floor(oldPageIndex / oldPageCount * this.pages.length);
            this.goToPage(newPageIndex, true);
        }
    }

    protected readBreakpoint(innerWidth : number) : string {
        const breakpoints = this.options.grid.breakpoints;
        const breakpointNamesOrdered = Object.keys(breakpoints).sort((aName, bName) => {
            return breakpoints[bName] - breakpoints[aName];
        });

        for(let i = 0; i < breakpointNamesOrdered.length; ++i) {
            if(breakpoints[breakpointNamesOrdered[i]] <= innerWidth) {
                return breakpointNamesOrdered[i];
            }
        }

        return breakpointNamesOrdered[0];
    }
// endregion GRID

// region MOUSEWHEEL
    protected isMouseWheelHandlerListening = true;
    protected shouldMouseWheelHandlerCheckDeltaPosition = false;

    protected initMouseWheel() {
        if(!this.options.mouseWheel || !this.options.mouseWheel.enabled) {
            return;
        }

        this.registerEventListener(
            this.getDOMElement(),
            'wheel',
            throttle(this.options.mouseWheel.throttleDelay, this.onMouseWheel.bind(this)),
            passiveEvent
        );
    }

    protected onMouseWheel(event : WheelEvent) {
        if(!this.options.mouseWheel) {
            return;
        }

        if(this.isMouseWheelHandlerListening && !this.shouldMouseWheelHandlerCheckDeltaPosition) {
            this.isMouseWheelHandlerListening = false;
            this.shouldMouseWheelHandlerCheckDeltaPosition = true;
        } else if(!this.isMouseWheelHandlerListening && this.shouldMouseWheelHandlerCheckDeltaPosition) {
            const relative = sign(event.deltaX);

            if(relative !== 0) {
                if(this.options.mouseWheel.stepType === 'page') {
                    this.goPage(relative);
                } else {
                    this.go(relative)
                }
            }

            this.shouldMouseWheelHandlerCheckDeltaPosition = false;
            setTimeout(() => {
                this.isMouseWheelHandlerListening = true;
            }, this.options.mouseWheel.timeoutDelay);
        }
    }
// endregion MOUSEWHEEL

// region SWIPE
    protected swipeManager : HammerManager|null = null;
    protected swipeAntiGhostPoints : Array<[number, number]> = [];
    protected isSwiping = false;
    protected currentPanEvent : HammerInput|null = null;

    protected initSwipe() {
        if(!this.options.swipe || !this.options.swipe.enabled) {
            return;
        }

        // bind this to onPanFrame, so we do not need to rebind in requestAnimationFrame
        this.onPanFrame = this.onPanFrame.bind(this);

        this.swipeManager = new Hammer.Manager(this.getDOMElement());

        this.swipeManager.add(new Hammer.Pan({ direction: Hammer.DIRECTION_HORIZONTAL }));
        this.swipeManager.on('panstart', this.onPanStart.bind(this));
        this.swipeManager.on('panmove', this.onPanMove.bind(this));
        this.swipeManager.on('panend', this.onPanEnd.bind(this));

        // unregister default click event listener
        this.unregisterEventListener(this.getDOMElement(), 'click', this.onClick, false);
        // register the anti ghost click listener
        this.registerEventListener(this.getDOMElement(), 'click', this.onPanAntiGhost.bind(this), false);

        if(this.options.swipe.disableDragging) {
            // disable dragging of links/images inside the slider (firefox, safari)
            this.registerEventListener(this.getDOMElement(), 'dragstart', (event : DragEvent) => {
                event.preventDefault();
                return false;
            }, false);
        }

        this.addEventListener('destroy', () => {
            this.swipeManager?.destroy();
        });
    }

    protected onPanFrame() {
        if(!this.options.swipe || !this.options.swipe.enabled || this.state.disabled || !this.isSwiping) {
            return;
        }

        if(!this.currentPanEvent) {
            return;
        }

        window.requestAnimationFrame(this.onPanFrame);

        const deltaX = (isRtl() ? (0 - this.currentPanEvent.deltaX) : this.currentPanEvent.deltaX);

        if(Math.abs(this.currentPanEvent.deltaY) > Math.abs(deltaX)) {
            return;
        }

        if(!this.dispatchEvent(createCustomEvent('pan', { hammer: this.currentPanEvent, deltaX: deltaX }))) {
            return;
        }

        if(
            (deltaX > 0 && !this.hasPrev(this.options.swipe.stepType))
            || (deltaX < 0 && !this.hasNext(this.options.swipe.stepType))
        ) {
            if(!this.options.swipe.endAnimation) {
                return;
            }

            this.updatePositions(this.options.swipe.endAnimation(Math.abs(deltaX), sign(deltaX)), true);
        }  else {
            this.updatePositions(deltaX, true);
        }
    }

    protected onPanStart(event : HammerInput) {
        if(!this.options.swipe || !this.options.swipe.enabled || this.state.disabled) {
            return;
        }

        this.items.forEach(item => item.setTransition(false));

        this.isSwiping = true;
        this.currentPanEvent = event;

        window.requestAnimationFrame(this.onPanFrame);
        event.preventDefault();
    }

    protected onPanMove(event : HammerInput) {
        if(!this.options.swipe || !this.options.swipe.enabled) {
            return;
        }

        this.currentPanEvent = event;
        event.preventDefault();
    }

    protected onPanEnd(event : HammerInput) {
        if(!this.options.swipe || !this.options.swipe.enabled) {
            return;
        }

        this.isSwiping = false;
        this.currentPanEvent = null;

        if(this.state.disabled) {
            return;
        }

        const direction = sign(event.deltaX) * (isRtl() ? 1 : -1);
        const distance = Math.abs(event.deltaX);
        const velocity = Math.abs(event.velocityX);

        const isSwipeOverHalfWidth = distance > this.state.width / 2;
        const isSwipeVelocityFast = velocity > this.options.swipe.minSwipeVelocity
                                    && distance > this.options.swipe.minSwipeWidth;

        this.pushPanAntiGhost(event.center.x, event.center.y);

        if(isSwipeOverHalfWidth || isSwipeVelocityFast) {
            if(direction > 0 && this.hasNext(this.options.swipe.stepType)) {
                if(this.options.swipe.stepType === 'page') {
                    return this.goNextPage();
                }

                return this.goNext();
            }

            if(direction < 0 && this.hasPrev(this.options.swipe.stepType)) {
                if(this.options.swipe.stepType === 'page') {
                    return this.goPrevPage();
                }

                return this.goPrev();
            }
        }

        this.updatePositions(0, false);
    }

    protected pushPanAntiGhost(x : number, y : number) {
        this.swipeAntiGhostPoints.push([x, y]);

        setTimeout(() => {
            this.swipeAntiGhostPoints.splice(0, 1);
        }, (this.options.swipe && this.options.swipe.antiGhostDelay) || 0);
    }

    protected onPanAntiGhost(event : MouseEvent) {
        if(!this.options.swipe || !this.options.swipe.enabled) {
            return this.onClick(event);
        }

        if(this.state.inAnimation) {
            event.preventDefault();
            return false;
        }

        const clientX = event.clientX;
        const clientY = event.clientY;

        for(let i = 0; i < this.swipeAntiGhostPoints.length; ++i) {
            const deltaX = Math.abs(clientX - this.swipeAntiGhostPoints[i][0]);
            const deltaY = Math.abs(clientY - this.swipeAntiGhostPoints[i][1]);

            if(deltaX < this.options.swipe.antiGhostDistance && deltaY < this.options.swipe.antiGhostDistance) {
                event.preventDefault();
                return false;
            }
        }

        return this.onClick(event);
    }
// endregion SWIPE

// region ARROWS
    protected initArrows() {
        if(!this.options.arrows || !this.options.arrows.enabled) {
            return;
        }

        const prevElement = this.getDOMElement(this.options.arrows.selectors.prev);
        const nextElement = this.getDOMElement(this.options.arrows.selectors.next);
        const goPrev = this.options.arrows.stepType === 'page' ? this.goPrevPage : this.goPrev;
        const goNext = this.options.arrows.stepType === 'page' ? this.goNextPage : this.goNext;

        this.registerEventListener(prevElement, 'click', goPrev.bind(this, undefined), passiveEvent);
        this.registerEventListener(nextElement, 'click', goNext.bind(this, undefined), passiveEvent);

        this.addEventListener('update', this.updateArrows.bind(this));

        if(this.options.arrows.adaptHeightTo) {
            this.addEventListener('adapt-height', this.resizeArrows.bind(this));
        }
    }

    protected updateArrows() {
        if(!this.options.arrows || !this.options.arrows.enabled) {
            return;
        }

        let showPrev = false;
        let showNext = false;

        if(!this.state.disabled && (
            this.items.length > 1 || (
                this.options.infinite && this.options.infinite.enabled && this.isInfiniteActive
            )
        )) {
            showPrev = this.hasPrev(this.options.arrows.stepType);
            showNext = this.hasNext(this.options.arrows.stepType);
        }

        this.getDOMElement(this.options.arrows.selectors.prev)
            .classList.toggle(this.options.arrows.classes.hidden, !showPrev);
        this.getDOMElement(this.options.arrows.selectors.next)
            .classList.toggle(this.options.arrows.classes.hidden, !showNext);
    }

    protected resizeArrows() {
        if(!this.options.arrows || !this.options.arrows.enabled || !this.options.arrows.adaptHeightTo) {
            return;
        }

        const currentPage = this.pages[this.state.pageIndex];
        let firstItem : BaseSliderItem;
        let lastItem : BaseSliderItem;

        if(currentPage) {
            firstItem = currentPage.items[0];
            lastItem = currentPage.items[currentPage.items.length - 1];
        } else {
            firstItem = lastItem = this.items[this.state.index];
        }

        this.setArrowAdaptiveHeightForItem(this.options.arrows.selectors.prev, firstItem);
        this.setArrowAdaptiveHeightForItem(this.options.arrows.selectors.next, lastItem);
    }

    protected setArrowAdaptiveHeightForItem(arrowSelector : string, item : BaseSliderItem) {
        if(!this.options.arrows || !this.options.arrows.enabled || !this.options.arrows.adaptHeightTo) {
            return;
        }

        const arrow = this.getDOMElement(arrowSelector);
        const element = item.getElement().querySelector(this.options.arrows.adaptHeightTo);

        if(!(element instanceof HTMLElement)) {
            arrow.style.removeProperty('height');
            return;
        }

        arrow.style.height = element.getBoundingClientRect().height + 'px';
    }
// endregion ARROWS

// region NAV
    protected initNav() {
        if(!this.options.nav || !this.options.nav.enabled) {
            return;
        }

        this.getDOMList(this.options.nav.selectors.items).forEach((navItem, index) => {
            this.registerEventListener(navItem, 'click', () => this.goTo(index), passiveEvent);
        });

        this.addEventListener('update', this.updateNav.bind(this));
    }

    protected updateNav() {
        if(!this.options.nav || !this.options.nav.enabled) {
            return;
        }

        const navItems = this.getDOMList(this.options.nav.selectors.items);
        let displayedNavItemsCount = this.count();
        let activeIndex = this.getIndex();

        for(let index = 0; index < navItems.length; ++index) {
            const navItem = navItems[index];

            navItem.classList.toggle(this.options.nav.classes.hidden, index >= displayedNavItemsCount);
            navItem.classList.toggle(this.options.nav.classes.active, index === activeIndex);
        }
    }
// endregion NAV

// region INFINITE
    protected isInfiniteActive : boolean = false;
    protected isInfiniteGoListening : boolean = true;

    protected initInfinite() {
        if(!this.options.infinite || !this.options.infinite.enabled) {
            return;
        }

        this.addEventListener('before-update', this.onInfinitePagesBeforeUpdate.bind(this));
        this.addEventListener('go', this.onInfinitePagesGo.bind(this));
        this.addEventListener('destroy', this.destroyInfiniteClones.bind(this));
    }

    protected onInfinitePagesBeforeUpdate(event : BaseSliderUpdateEvent) {
        if(!event.forceUpdate) {
            return;
        }

        if(!this.options.infinite || !this.options.infinite.enabled) {
            return;
        }

        const realItems = this.items.filter(item => !item.isClone());

        if(realItems.length < 1) {
            return;
        }

        this.isInfiniteActive = true;

        if(!this.options.infinite.enabledWhenSinglePage) {
            let realItemPages = 0;

            for (let i = 0; i < realItems.length; ++i) {
                realItemPages += realItems[i].getSizeRatio(this.state.breakpoint);

                if (realItemPages > 1) {
                    break;
                }
            }

            // when realItems are only the size of a single page, and enabledWhenSinglePage is false, do not clone items
            if (realItemPages <= 1) {
                this.isInfiniteActive = false;
                // cleanup old infinite clones if they exist
                this.destroyInfiniteClones();
                return;
            }
        }

        const clonedItemsAppend = [];
        let clonedItemsAppendChanged = false;
        let clonedItemsAppendWidth = 0;
        let itemIndex = 0;

        while(clonedItemsAppendWidth < 2) {
            const itemToClone = realItems[itemIndex % realItems.length];
            const currentClone = this.clonedItemsAppended[clonedItemsAppend.length];
            let clone : BaseSliderItem;

            if(!clonedItemsAppendChanged && currentClone && itemToClone.getRawId() === currentClone.getRawId()) {
                clone = currentClone;
            } else {
                clonedItemsAppendChanged = true;
                clone = itemToClone.clone("appended");
            }

            clonedItemsAppendWidth += clone.getSizeRatio(this.state.breakpoint);
            clonedItemsAppend.push(clone);
            itemIndex++;
        }

        if(!clonedItemsAppendChanged && clonedItemsAppend.length !== this.clonedItemsAppended.length) {
            clonedItemsAppendChanged = true;
        }

        const clonedItemsPrepend = [];
        let clonedItemsPrependChanged = false;
        let clonedItemsPrependWidth = 0;
        itemIndex = realItems.length - 1;

        while(clonedItemsPrependWidth < 2) {
            if(itemIndex < 0) {
                itemIndex += realItems.length;
            }

            const itemToClone = realItems[itemIndex % realItems.length];
            const indexInPrepended = Math.max(0, this.clonedItemsPrepended.length - clonedItemsPrepend.length - 1);
            const currentClone = this.clonedItemsPrepended[indexInPrepended];
            let clone : BaseSliderItem;

            if(!clonedItemsPrependChanged && currentClone && itemToClone.getRawId() === currentClone.getRawId()) {
                clone = currentClone;
            } else {
                clone = itemToClone.clone("prepended");
                clonedItemsPrependChanged = true;
            }

            clonedItemsPrependWidth += clone.getSizeRatio(this.state.breakpoint);
            clonedItemsPrepend.unshift(clone);
            itemIndex--;
        }

        if(!clonedItemsPrependChanged && clonedItemsPrepend.length !== this.clonedItemsPrepended.length) {
            clonedItemsPrependChanged = true;
        }

        if(!clonedItemsAppendChanged && !clonedItemsPrependChanged) {
            return;
        }

        if(clonedItemsPrependChanged) {
            // update slide index when number of infiniteItems changed
            this.state.index = this.state.index - this.clonedItemsPrepended.length + clonedItemsPrepend.length;

            // exchange old prepended items with new ones
            this.items.splice(0, this.clonedItemsPrepended.length, ...clonedItemsPrepend);
            // caching
            this.clonedItemsPrepended.splice(0, this.clonedItemsPrepended.length, ...clonedItemsPrepend);
        }

        if(clonedItemsAppendChanged) {
            // exchange old appended items with new ones
            this.items.splice(this.items.length - this.clonedItemsAppended.length, this.items.length, ...clonedItemsAppend);
            // caching
            this.clonedItemsAppended.splice(0, this.clonedItemsAppended.length, ...clonedItemsAppend);
        }

        const container = this.getDOMElement(this.options.selectors.container);
        const newChildren : Array<Element> = this.items.map(item => item.getElement());
        const itemClass = this.options.selectors.items.substr(1);

        rearrangeDom(container, newChildren, element => {
            // skip additional elements inside the container (e.g. the arrow-navigation elements)
            return !element.classList.contains(itemClass);
        });
    }

    protected onInfinitePagesGo(event : BaseSliderGoEvent) {
        if(
            !this.options.infinite
            || !this.options.infinite.enabled
            || !this.isInfiniteGoListening
            || !this.isInfiniteActive
        ) {
            return;
        }

        this.isInfiniteGoListening = false;

        const realItemCount = this.items.length - this.clonedItemsPrepended.length - this.clonedItemsAppended.length;

        if(event.relative > 0 && event.index >= this.items.length - this.clonedItemsAppended.length) {
            // inside append
            this.goTo(event.index - realItemCount, true, true);
        } else if(event.relative < 0 && event.index < this.clonedItemsPrepended.length) {
            // inside prepend
            this.goTo(event.index + realItemCount, true, true);
        }

        this.isInfiniteGoListening = true;
    }

    protected destroyInfiniteClones() {
        const nonClonedElements : Array<HTMLElement> = [];
        let hasRemovedClones : boolean = false;

        for(let i = 0; i < this.items.length; ++i) {
            const item = this.items[i];

            if(!item.isClone()) {
                nonClonedElements.push(item.getElement());
                continue;
            }

            hasRemovedClones = true;
            this.items.splice(i, 1);
            i--;
        }

        if(!hasRemovedClones) {
            return;
        }

        const container = this.getDOMElement(this.options.selectors.container);
        const itemClass = this.options.selectors.items.substr(1);
        this.state.index = this.state.index - this.clonedItemsPrepended.length;
        // this.state.pageIndex will be updated in updatePages since this is called in before-update (or destroy) event

        this.clonedItemsPrepended.splice(0, this.clonedItemsPrepended.length);
        this.clonedItemsAppended.splice(0, this.clonedItemsAppended.length);

        rearrangeDom(container, nonClonedElements, element => {
            // skip additional elements inside the container (e.g. the arrow-navigation elements)
            return !element.classList.contains(itemClass);
        }); 
    }
// endregion INFINITE

// region AUTO_ROTATE
    protected autoRotateTimeoutRef : number = -1;
    protected autoRotatePauseTimeoutRef : number = -1;
    protected isAutoRotateHover : boolean = false;

    protected initAutoRotate() {
        if(!this.options.autoRotate || !this.options.autoRotate.enabled) {
            return;
        }

        this.autoRotate = this.autoRotate.bind(this);

        const autoRotateDelay = this.options.autoRotate.delay;

        this.addEventListener('initialized', () => {
            this.autoRotateTimeoutRef = setTimeout(this.autoRotate, autoRotateDelay) as any;
        });

        if(this.options.autoRotate.stopOnHover) {
            this.registerEventListener(this.getDOMElement(), 'mouseenter', () => {
                this.isAutoRotateHover = true;
                this.pauseAutoRotate();
            }, passiveEvent);
            this.registerEventListener(this.getDOMElement(), 'mouseleave', () => {
                this.isAutoRotateHover = false;
                this.pauseAutoRotate();
            }, passiveEvent);
        }

        if(this.options.autoRotate.stopOnClick) {
            this.addEventListener('pan', this.pauseAutoRotate.bind(this));
            this.registerEventListener(this.getDOMElement(), 'click', this.pauseAutoRotate.bind(this), passiveEvent);
        }

        this.addEventListener('destroy', () => {
            clearTimeout(this.autoRotateTimeoutRef);
            clearTimeout(this.autoRotatePauseTimeoutRef);
        });
    }

    protected autoRotate() {
        if(!this.options.autoRotate || !this.options.autoRotate.enabled) {
            return;
        }

        const activeElement = document.activeElement;

        if(!activeElement || !this.getDOMElement().contains(activeElement)) {
            const autoRotateRelative = this.options.autoRotate.enabled === 'reverse' ? -1 : 1;

            if (this.options.autoRotate.stepType === 'page') {
                this.goPage(autoRotateRelative);
            } else {
                this.go(autoRotateRelative);
            }
        }

        this.autoRotateTimeoutRef = setTimeout(this.autoRotate, this.options.autoRotate.delay) as any;
    }

    protected pauseAutoRotate() {
        if(!this.options.autoRotate || !this.options.autoRotate.enabled) {
            return;
        }

        clearTimeout(this.autoRotateTimeoutRef);
        clearTimeout(this.autoRotatePauseTimeoutRef);

        if(!this.isAutoRotateHover) {
            this.autoRotatePauseTimeoutRef = setTimeout(this.autoRotate, this.options.autoRotate.delay) as any;
        }
    }
// endregion AUTO_ROTATE

// region INTERNAL_HELPERS
    protected getLastItemIndex(): number {
        const itemsOnLastPage = this.pages[this.pages.length - 1].items.length;
        return this.items.length - itemsOnLastPage;
    }

    private static readonly gridColumnSizeRegExpCache : { [key : string]: RegExp } = {};
    private static readonly gridColumnSizeRegExpDefault = /as__col-([0-9]+)/i;

    private static createPage(startIndex : number = 0) : BaseSliderPage {
        return {
            items: [],
            startIndex: startIndex,
            width: 0
        };
    }

    private static getGridColumnSizeDefault(element : HTMLElement, breakpoint : string) : number {
        if(!(breakpoint in BaseSlider.gridColumnSizeRegExpCache)) {
            BaseSlider.gridColumnSizeRegExpCache[breakpoint] = new RegExp('as__col-' + breakpoint + '-([0-9]+)', 'i');
        }

        let matches = element.className.match(BaseSlider.gridColumnSizeRegExpCache[breakpoint]);

        if(matches) {
            return parseInt(matches[1], 10);
        }

        if(breakpoint !== 'xs') {
            return 0;
        }

        matches = element.className.match(BaseSlider.gridColumnSizeRegExpDefault);

        if(matches) {
            return parseInt(matches[1], 10);
        }

        return 0;
    }

    private static defaultItemHeightCalculator(item : BaseSliderItem, options : BaseSliderOptions): number {
        if (!options.grid.adaptItemHeight) {
            return item.getHeight();
        }

        const children = item.getElement().children;
        let height: number = 0;

        for (let i = 0; i < children.length; ++i) {
            height += BaseSlider.getElementHeightIncludingMargin(children[i]);
        }

        return height;
    }

    private static getElementHeightIncludingMargin(element : Element): number {
        if(!(element instanceof HTMLElement)) {
            return element.clientHeight;
        }

        const styles = window.getComputedStyle(element);
        const margin = parseFloat(styles['marginTop']) + parseFloat(styles['marginBottom']);

        return Math.ceil(element.offsetHeight + margin);
    }
// endregion INTERNAL_HELPERS
}
