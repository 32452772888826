import {DeepPartial} from "./deepPartial";

export default function assignOptions<O extends object>(partialOptions : DeepPartial<O>, defaultOptions : O) : O {
    const options = {} as O;
    partialOptions = partialOptions || {};

    for(let key in defaultOptions) {
        if(!defaultOptions.hasOwnProperty(key)) {
            continue;
        }

        if(key in partialOptions) {
            if(typeof defaultOptions[key] === 'object' && typeof partialOptions[key] === 'object') {
                options[key] = assignOptions(partialOptions[key] as any, defaultOptions[key] as any);
            } else if(typeof partialOptions[key] === 'object') {
                options[key] = assignOptions(partialOptions[key] as any, {}) as any;
            } else {
                options[key] = partialOptions[key] as any;
            }
        } else if(typeof defaultOptions[key] === 'object') {
            options[key] = assignOptions({}, defaultOptions[key] as any);
        } else {
            options[key] = defaultOptions[key];
        }
    }

    return options;
}
