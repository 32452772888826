import Breakpoints from '../../settings/breakpoints';
import onDOMContentLoaded from "../util/dom/onDOMContentLoaded";
import passiveEvent from '../util/dom/passiveEvent';
import {HeaderMobileHeight} from '../../modules/sr1__header/header';

window.addEventListener('hashchange', onHashChange, passiveEvent);
onDOMContentLoaded(init);

const AdditionalAnchorOffset = 20;
let currentHash : string|null = null;
let currentScrollToElement : HTMLElement|null = null;

function init() {
    document.querySelectorAll("img").forEach(image => {
        if(image.complete) {
            return;
        }

        image.addEventListener('load', () => {
            if(!currentScrollToElement) {
                return;
            }

            scrollToElement(currentScrollToElement);
        }, passiveEvent);
    })

    document.addEventListener('touchmove', resetCurrentScrollToElement, passiveEvent);
    document.addEventListener('wheel', resetCurrentScrollToElement, passiveEvent);
    onHashChange();
}

function onHashChange() {
    let hash = window.location.hash;

    if(hash.indexOf('#') === 0) {
        hash = hash.substr(1);
    }

    if(currentHash === hash) {
        return;
    }

    currentHash = hash;

    if(!hash) {
        return;
    }

    const element = document.getElementById(hash);

    if(!(element instanceof HTMLElement)) {
        return;
    }

    currentScrollToElement = element;
    scrollToElement(element);
}

function resetCurrentScrollToElement(): void {
    currentScrollToElement = null;
}

function scrollToElement(element : HTMLElement): void {
    let top : number = element.offsetTop;

    if(Breakpoints.isMobile()) {
        top -= HeaderMobileHeight + AdditionalAnchorOffset;
    }

    window.scrollTo({
        top: top,
        left: 0,
        behavior: 'smooth'
    });
}