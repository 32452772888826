import onDOMContentLoaded from "./base/util/dom/onDOMContentLoaded";

// import main.less to compile less
import './main.less';

// polyfills
import 'core-js/features/object/assign';
import 'core-js/features/dom-collections';
import 'picturefill';
(require('smoothscroll-polyfill') as any).polyfill();
import objectFitImages = require('object-fit-images');

onDOMContentLoaded(() => {
    document.body.classList.remove('no-js');
    objectFitImages(null);
});

// base
import './base/base';
import './base/icon/icon';
import './base/alert/alert';
import './base/form/form';
import './base/form/validation'
import './base/link/anchor-links';
import './base/tooltip/tooltip';

// components
import './components/modal/modal';
import './components/carousel/carousel';
import './components/product-teaser/product-teaser';
import './components/product-teaser/cart';

// modules
import './modules/sr1__header/header';
import './modules/sr3__footer/footer';
import './modules/kud04__register/register';
import './modules/com05__login/login';
import './modules/m__sub-nav/sub-nav';
import './modules/m__dbv-abo/dbv-abo';

import './modules/m01__hero/hero';
import './modules/m06__product-teaser-slider/product-teaser-slider';
import './modules/m07__accordion/accordion';
import './modules/m08__newsletter/newsletter';

// sites
import './sites/layout/layout';
import './sites/account/index';
import './sites/account/reset-password/reset-password';
import './sites/account/send-password-recovery-mail/send-password-recovery-mail';
import './sites/cart/flyout';

// product
import './sites/product/partial/media-carousel';
import './sites/product/partial/cross-selling-carousel';
import './sites/product/partial/buybox';
import './sites/product/partial/buy';
import './sites/product/sidebar-nav';
import './sites/product/filters';

// checkout
import './sites/checkout/order-summary-sticky';
