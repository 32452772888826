import onDOMContentLoaded from "../../../base/util/dom/onDOMContentLoaded";

onDOMContentLoaded(() => {
    const radioWrapperCheckedHandlerFor = (className: string) => {
        document.querySelectorAll(className).forEach((radios) => {
            if(!(radios instanceof HTMLElement)) {
                return;
            }

            let controls = radios.querySelectorAll('.as__radio__control');

            controls.forEach((control) => {
                control.addEventListener('click', () => {
                    controls.forEach((control) => {
                        if(!(control instanceof HTMLInputElement)) {
                            return;
                        }

                       control.parentElement?.classList.toggle('as__radio--checked', control.checked);
                    });
                });
            })
        });
    }

    radioWrapperCheckedHandlerFor('.firb__product-buybox__bonus-radios');
    radioWrapperCheckedHandlerFor('.firb__product-buybox__variant-radios');
});