import BaseAccordion, {BaseAccordionUpdateItemEvent} from "../../components/base-accordion/base-accordion";
import onDOMContentLoaded from "../../base/util/dom/onDOMContentLoaded";
import animateHeightTransition from "../../base/util/dom/animateHeightTransition";

onDOMContentLoaded(() => {
    const options = {
        filterContainer: '.firb__product-filter',
        selectors: {
            accordion: '.firb__product-filter__accordion',
            toggle: '.as__accordion__toggle'
        },
        itemFactory: (toggle : HTMLInputElement) => {
            const trigger = toggle.nextElementSibling;
            return {
                toggle: toggle,
                triggers: trigger ? [trigger] : [],
                contents: trigger && trigger.nextElementSibling ? [trigger.nextElementSibling] : [],
                open: toggle.checked
            };
        },
        filterContainsActiveItem: (item: HTMLElement) => {
            const controls = item.querySelectorAll('.as__checkbox__control, .as__radio__control');
            const checkedItems = Array.prototype.slice.call(controls).filter((control: HTMLInputElement) => control.checked);

            if (checkedItems.length) {
                item.classList.add('firb__product-filter__filter-is-active');
            } else {
                item.classList.remove('firb__product-filter__filter-is-active');
            }
        }
    };

    const containers = document.querySelectorAll(options.filterContainer);
    const desktopFilter = document.querySelector(options.filterContainer + '[data-device-type="desktop"]');
    const accordions = document.querySelectorAll(options.selectors.accordion);

    if (!accordions && !containers) {
        return;
    }

    accordions.forEach((root) => {
        if (!(root instanceof HTMLElement)) {
            return;
        }

        const accordion = new BaseAccordion(root, options);

        options.filterContainsActiveItem(root);

        accordion.addEventListener('updateItem', (event : BaseAccordionUpdateItemEvent) => {
            event.item.contents.forEach(content => {
                animateHeightTransition(content, event.item.open);
            });

            if(event.item.open) {
                event.item.toggle.parentElement?.classList.add('as__accordion__item--open');
            } else {
                event.item.toggle.parentElement?.classList.remove('as__accordion__item--open');
            }
        });

        document.addEventListener('click', (event: MouseEvent) => {
            if(!(event.target instanceof HTMLElement)){
                return;
            }

            if (!event.target.closest('.as__accordion__item')) {
                accordion.closeAll();
            }
        });
    });

    containers.forEach(container => {
        if(!(container instanceof HTMLElement)) {
            return;
        }

        container.querySelectorAll('.firb__product-filter__single-checkbox').forEach((checkbox) => {
            if (!(checkbox instanceof HTMLElement)) {
                return;
            }

            checkbox.querySelector('.as__checkbox__control')?.addEventListener('click', () => {
                checkbox.classList.toggle('firb__product-filter__filter-is-active');
            });
        });
    });

    if (desktopFilter instanceof HTMLElement) {
        desktopFilter.querySelectorAll('.as__checkbox__control, .as__radio__control').forEach((control) => {
            if (!(control instanceof HTMLElement)) {
                return;
            }

            control.addEventListener('click', () => {
                let submit = desktopFilter.querySelector('input[type="submit"]');

                if (!(submit instanceof HTMLElement)) {
                    return;
                }

                submit.click();
            });
        });
    }

    document.querySelectorAll('.as__accordion__item').forEach((item) => {
        if (!(item instanceof HTMLElement)) {
            return;
        }

        options.filterContainsActiveItem(item);

        item.addEventListener('click', () => {
            options.filterContainsActiveItem(item);
        });
    });
});
