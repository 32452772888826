import onDOMContentLoaded from '../../base/util/dom/onDOMContentLoaded';

const Selectors = {
    root: '.firb__dbv-abo',
    closeBtn: '.firb__dbv-abo__close-btn'
}

onDOMContentLoaded(() => {
    const dbvAbo = <HTMLElement>document.querySelector(Selectors.root);

    if (!(dbvAbo instanceof HTMLElement)) {
        return;
    }

    if(sessionStorage.getItem('dbvAbo:dismissed') === null) {
        sessionStorage.setItem('dbvAbo:dismissed', '0');
    }

    let isVisible = sessionStorage.getItem('dbvAbo:dismissed') === '0';
    if(isVisible) {
        dbvAbo.classList.remove('as__hide');
    }

    const closeBtn = <HTMLButtonElement>dbvAbo.querySelector(Selectors.closeBtn);
    if(closeBtn instanceof HTMLButtonElement) {
        closeBtn.addEventListener('click',(ev)=>{
            dbvAbo.classList.add('as__hide');
            isVisible = false;
            sessionStorage.setItem('dbvAbo:dismissed', '1');
        }, false);
    }
})
