import onDOMContentLoaded from '../../base/util/dom/onDOMContentLoaded';

onDOMContentLoaded(() => {
    const root = document.querySelector('.firb__com05-login');
    if (!root) {
        return;
    }

    const form = <HTMLFormElement>root.querySelector('form');
    Array.from(form.elements).map((element) => {
        element.addEventListener('focus', (e) => {
            let target = <HTMLElement>e.target;
            if (target.nodeName !== 'INPUT') {
                const parent = <HTMLElement>target.parentElement;
                if (parent) {
                    target = <HTMLElement>parent.querySelector('input');
                }
            }
            if (target) {
                target.classList.remove('as__field-error');
                const wrapper = <HTMLElement>target.closest('div');
                const info = wrapper.querySelector('.as__info-error');
                if (info) {
                    info.classList.add('as__hide');
                }
            }
        });
    });
});