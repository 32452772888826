import AbstractComponent from "./AbstractComponent";

export default abstract class AbstractTemplatingComponent<O, E> extends AbstractComponent<O, E> {
    protected renderTemplate(
        template : HTMLTemplateElement|string,
        slots : Record<string, any> = {},
        safeHtml : boolean = false
    ): DocumentFragment {
        if(typeof template === "string") {
            template = this.getTemplate(template);
        }

        const fragment = template.content.cloneNode(true) as DocumentFragment;
        const slotNames = Object.keys(slots);

        for(let slotName of slotNames) {
            const value = slots[slotName];
            const slotElements = fragment.querySelectorAll(
                'slot[name="' + slotName + '"], [data-slot-name="' + slotName + '"]'
            );

            for(let i = 0; i < slotElements.length; ++i) {
                this.renderSlotChildren(slotElements[i], value, safeHtml);
            }
        }

        return fragment;
    }

    protected renderSlotChildren(slot : Element, value : any, safeHtml : boolean = false): void {
        if(typeof value === "undefined" || value === null) {
            return;
        }

        if(value instanceof Node) {
            slot.appendChild(value);
            return;
        }

        if(Array.isArray(value)) {
            value.forEach(item => this.renderSlotChildren(slot, item));
        } else {
            if(safeHtml) {
                value = this.getDocumentFragmentFromHTML(value);
            } else {
                value = document.createTextNode(String(value));
            }

            slot.appendChild(value);
        }
    }
}