import onDOMContentLoaded from "../../base/util/dom/onDOMContentLoaded";
import { clamp } from  'clamp.ts';

onDOMContentLoaded(() => {
    document.querySelectorAll('.firb__m20-product-tile__subtext').forEach(element => {
        let lines = element.classList.contains('firb__m20-product-tile__subtext--hero') ? 2 : 3;

        clamp(element, {
            clamp: lines,
        });
    });
});