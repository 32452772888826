import onDOMContentLoaded from '../../../base/util/dom/onDOMContentLoaded';
import {addResetFormErrorsEventListener} from '../../../base/util/formErrorsHelpers';

const showErrorInfo = (element: HTMLElement) => {
    const wrapper = <HTMLElement>element.closest('div');
    const info = wrapper.querySelector('.as__info-error');
    if (info) {
        info.classList.remove('as__hide');
    }
};

onDOMContentLoaded(() => {
    const root = document.querySelector('.firb__account-reset-password');
    if (!root) {
        return;
    }

    const button = <HTMLButtonElement>root.querySelector('button[name="_submit"]');
    const form = <HTMLFormElement>root.querySelector('form');
    addResetFormErrorsEventListener(form);

    const requiredItems = [
        "password",
        "password-repeat",
    ];

    if (button){
        button.addEventListener('click', () => {
            let isValid = true;
            requiredItems.forEach((name) => {
                const element = <HTMLFormElement>form.elements.namedItem(name);
                if (element && !element.checkValidity()) {
                    isValid = false;
                    element.classList.add('as__field-error');
                    showErrorInfo(element);
                }
            });
            if (isValid){
                form.submit();
            }
        });
    }
});