import {createIcon} from "../../base/icon/icon";

const BodyHeightReCalcVariance = 300;
const WidthBreakpoint = 1440;

const BackgroundContentOffset = 1200;
const BackgroundFooterOffset = 1000;
const BackgroundItemHeight = 1000;
const BackgroundItemSpacing = 900;
const BackgroundContainerSelector = '.firb__layout__background-container';
const BackgroundItemClass = 'firb__layout__background';
const BackgroundItemContentClass = 'firb__layout__background--content';
const bodyElement = document.body;

let lastReCalcHeight = 0;

function recalculateLayoutBackground() {
    const layoutBackgroundContainer = document.querySelector(BackgroundContainerSelector);
    const bodyHeight = bodyElement.getBoundingClientRect().height;

    if(!(layoutBackgroundContainer instanceof HTMLElement)) {
        return;
    }

    // no need to recalculate if background is not displayed in smaller viewports
    if(window.innerWidth < WidthBreakpoint) {
        return;
    }

    // do not recalculate if height is nearly the same as in last calculation
    if(Math.max(lastReCalcHeight, bodyHeight) - Math.min(lastReCalcHeight, bodyHeight) <= BodyHeightReCalcVariance) {
        return;
    }

    lastReCalcHeight = bodyHeight;

    // delete old background items
    Array.prototype.slice.call(layoutBackgroundContainer.children).forEach(child => {
        if(child.classList.contains(BackgroundItemContentClass)) {
            layoutBackgroundContainer.removeChild(child);
        }
    });
    
    let remainingBodyHeight = bodyHeight - BackgroundContentOffset - BackgroundFooterOffset - BackgroundItemSpacing;
    let currentOffset = BackgroundContentOffset + BackgroundItemSpacing / 2;
    let currentSideIsLeft = false;

    if(remainingBodyHeight < 0) {
        return; // no items need to be added since height is too small
    }
    
    while((remainingBodyHeight = remainingBodyHeight - (BackgroundItemHeight + BackgroundItemSpacing)) > 0) {
        currentOffset += BackgroundItemSpacing / 2;

        layoutBackgroundContainer.appendChild(
            createBackgroundItem(currentSideIsLeft ? 'content-left' : 'content-right', currentOffset)
        );

        currentOffset += BackgroundItemHeight + BackgroundItemSpacing / 2;
        currentSideIsLeft = !currentSideIsLeft;
    }

    layoutBackgroundContainer.appendChild(createBackgroundItem(currentSideIsLeft ? 'footer-left' : 'footer-right'));
}

function createBackgroundItem(type : string, top?: number): SVGSVGElement {
    let svgUseHref ='#firb__body-background__' + type.replace('footer', 'top');

    // seminar-shop: adjust link:href for icons from body_background.svg
    let body = document.body;
    if (body && body.classList.contains('seminar-shop')) {
        let parts = svgUseHref.split('__');
        parts[1] = parts[1] + '-seminar-shop';
        svgUseHref = parts.join('__');
    }

    const svg = createIcon('', false, svgUseHref);
    svg.classList.add(BackgroundItemClass, BackgroundItemContentClass, 'firb__layout__background--' + type);

    if(typeof top === 'number') {
        svg.style.top = top + 'px';
    }

    return svg;
}

window.addEventListener('load',() => {
    recalculateLayoutBackground();

    if (typeof (window as any).ResizeObserver === "function") {
        const resizeObserver = new (window as any).ResizeObserver(() => {
            recalculateLayoutBackground();
        });

        resizeObserver.observe(bodyElement);
    } else {
        window.addEventListener('resize', recalculateLayoutBackground, { passive: true });
    }
});
