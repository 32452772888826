import EventTarget, {createCustomEvent} from "../base/util/dom/EventTarget";
import passiveEvent from "../base/util/dom/passiveEvent";

export type BreakpointType = "xxs"|"xs"|"sm"|"md"|"lg"|"xl"|"xxl";
export type BreakpointOrResolution = BreakpointType|number;

type BreakpointsInterfaceType = {
    [breakpoint in BreakpointType]: number;
};

let currentResolution : number = window.innerWidth;

class Breakpoints extends EventTarget implements BreakpointsInterfaceType {
    public readonly xxs = 0;
    public readonly xs = 375;
    public readonly sm = 576;
    public readonly md = 768;
    public readonly lg = 1024;
    public readonly xl = 1440;
    public readonly xxl = 1920;

    private readonly firstDesktopBreakpoint : BreakpointType = "lg";
    private currentBreakpoint : BreakpointType;
    private wasMobile : boolean;

    constructor() {
        super();

        this.currentBreakpoint = this.readCurrentBreakpoint();
        this.wasMobile = this.isMobile();
        window.addEventListener('resize', this.onResize.bind(this), passiveEvent);
    }

    current(): BreakpointType {
        return this.currentBreakpoint;
    }

    getCurrentResolution(): number {
        return currentResolution;
    }

    isGreaterThan(breakpoint : BreakpointOrResolution): boolean {
        return currentResolution > this.getResolutionForBreakpoint(breakpoint);
    }

    isGreaterOrEqualTo(breakpoint : BreakpointOrResolution): boolean {
        return currentResolution >= this.getResolutionForBreakpoint(breakpoint);
    }

    isLessThan(breakpoint : BreakpointOrResolution): boolean {
        return currentResolution < this.getResolutionForBreakpoint(breakpoint);
    }

    isLessOrEqualTo(breakpoint : BreakpointOrResolution): boolean {
        return currentResolution <= this.getResolutionForBreakpoint(breakpoint);
    }

    isBetween(minBreakpointInclusive : BreakpointOrResolution, maxBreakpointExclusive : BreakpointOrResolution): boolean {
        return this.isGreaterOrEqualTo(minBreakpointInclusive) && this.isLessThan(maxBreakpointExclusive);
    }

    isMobile(): boolean {
        return this.isLessThan(this.firstDesktopBreakpoint);
    }

    isDesktop(): boolean {
        return this.isGreaterOrEqualTo(this.firstDesktopBreakpoint);
    }

    getResolutionForBreakpoint(breakpoint : BreakpointOrResolution): number {
        if(typeof breakpoint === "number") {
            return breakpoint;
        }

        return this[breakpoint];
    }

    private onResize() {
        currentResolution = window.innerWidth;
        const newBreakpoint = this.readCurrentBreakpoint();
        const newIsMobile = this.isMobile();

        if(newBreakpoint === this.currentBreakpoint) {
            return;
        }

        this.dispatchEvent(createCustomEvent('resize', {
            fromBreakpoint: this.currentBreakpoint,
            toBreakpoint: newBreakpoint,
            width: currentResolution
        }));
        
        if(newIsMobile !== this.wasMobile) {
            this.dispatchEvent(createCustomEvent('resize-mobile-desktop', {
                wasMobile: this.wasMobile,
                isMobile: newIsMobile
            }));

            this.wasMobile = newIsMobile;
        }

        this.currentBreakpoint = newBreakpoint;
    }

    private readCurrentBreakpoint(): BreakpointType {
        const breakpoints = Object.keys(this).filter(key => typeof (this as any)[key] === "number") as Array<BreakpointType>;
        let currentBreakpoint : BreakpointType|undefined = breakpoints.shift();

        for(let bp of breakpoints) {
            if(currentResolution < this[bp]) {
                break;
            }

            currentBreakpoint = bp;
        }

        if(!currentBreakpoint) {
            throw new Error("No Breakpoint for at least 0 configured!");
        }

        return currentBreakpoint;
    }
}

export default new Breakpoints();