import onDOMContentLoaded from "../util/dom/onDOMContentLoaded";

onDOMContentLoaded(() => {
    document.querySelectorAll("input[type='checkbox'][name='dsgvo']").forEach((field)=>{
        if(!(field instanceof HTMLInputElement)) {
            return;
        }
        const message = "Um fortzufahren, bitten wir Sie die Datenschutzinformationen zu bestätigen.";

        if (field.validity.valueMissing) {
            field.setCustomValidity(message);
        }

        field.addEventListener("change", (event) => {
            if (field.validity.valueMissing) {
                field.setCustomValidity(message);
            } else {
                field.setCustomValidity("");
            }
        });
    });

    document.querySelectorAll("input[type='email']").forEach((email)=>{
        if(!(email instanceof HTMLInputElement)) {
            return;
        }
        const typeMismatchMessage = "Bitte geben Sie eine gültige Mailadresse mit @-Zeichen und Endung an (z.B. .de, .com, .org)";

        email.addEventListener("change", (event) => {
            if (email.validity.typeMismatch) {
                email.setCustomValidity(typeMismatchMessage);
            } else {
                email.setCustomValidity("");
            }
        });
    });
});
