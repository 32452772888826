document.addEventListener('DOMContentLoaded', () => {
    const tooltips = document.querySelectorAll('.firb__tooltip');
    const positionBottomClass = 'firb__tooltip--pos-bottom';

    Array.prototype.slice.call(tooltips).forEach((tooltip) => {
        const text = tooltip.querySelector('.firb__tooltiptext');

        if(text) {
            const bounding = text.getBoundingClientRect();

            if (!(bounding.top >= 0 && bounding.left >= 0 && bounding.right <= window.innerWidth && bounding.bottom <= window.innerHeight)) {
                tooltip.classList.add(positionBottomClass);
            }
        }
    })
});