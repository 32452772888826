import onDOMContentLoaded from "../util/dom/onDOMContentLoaded";
import AbstractComponent from "../util/AbstractComponent";
import {createIcon} from "../icon/icon";

export default class PasswordInput extends AbstractComponent<{}, {
    wrapper: HTMLElement;
    input: HTMLInputElement;
    toggle: HTMLButtonElement;
}>{
    public static readonly CachePropertyName : string = '__as_passwordInput';

    protected constructor(container : HTMLElement) {
        super(container, {});

        this.elements.wrapper = this.queryRequiredSelector('.as__input__wrapper');
        this.elements.input = this.queryRequiredSelector('.as__input__control');

        this.elements.toggle = document.createElement('button');
        this.elements.toggle.setAttribute('type', 'button');
        this.elements.toggle.setAttribute('aria-hidden', 'true');
        this.elements.toggle.classList.add('as__input__toggle-password');
        this.elements.toggle.addEventListener('click', this.onToggle.bind(this), false);
        this.elements.toggle.appendChild(createIcon('password-toggle'));
        this.elements.wrapper.appendChild(this.elements.toggle);

        (container as any)[PasswordInput.CachePropertyName] = this;
    }

    getContainer(): HTMLElement {
        return this.elements.container;
    }

    getInput(): HTMLInputElement {
        return this.elements.input;
    }

    isPasswordHidden(): boolean {
        return this.elements.input.type === 'password';
    }

    isPasswordVisible(): boolean {
        return this.elements.input.type !== 'password';
    }

    protected onToggle(): void {
        if(this.isPasswordHidden()) {
            this.elements.input.type = 'text';
        } else {
            this.elements.input.type = 'password';
        }
    }

    public static get(element : HTMLElement): PasswordInput {
        if((element as any)[PasswordInput.CachePropertyName]) {
            return (element as any)[PasswordInput.CachePropertyName] as PasswordInput;
        }

        return new PasswordInput(element);
    }
}

onDOMContentLoaded(() => {
    document.querySelectorAll('.as__input--password').forEach(element => {
        if(!(element instanceof HTMLElement)) {
            return;
        }

        PasswordInput.get(element);
    });
});