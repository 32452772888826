import onDOMContentLoaded from '../../base/util/dom/onDOMContentLoaded';
import {addResetFormErrorsEventListener, showErrorInfo} from '../../base/util/formErrorsHelpers';


const reportErrors = (form: HTMLFormElement) => {
    let isValid = true;

    Array.from(form.elements).forEach(element => {
        if(!(element instanceof HTMLInputElement) || !element.required || !element.name) {
            return;
        }
        
        if(element.checkValidity()) {
            return;
        }

        isValid = false;
        element.classList.add('as__field-error');
        showErrorInfo(element);
    });

    return isValid;
};

onDOMContentLoaded(() => {
    const form = document.querySelector('.firb__m08-newsletter .as__form');

    if (!(form instanceof HTMLFormElement)) {
        return;
    }

    addResetFormErrorsEventListener(form);

    const submitButton = form.querySelector<HTMLButtonElement>('button[type="submit"], input[type="submit"]');
    
    if(!(submitButton instanceof HTMLElement)) {
        return;
    }

    submitButton.addEventListener('click', (e) => {
        const isValid = reportErrors(form);

        if (!isValid) {
            e.preventDefault();
        }
    });
});