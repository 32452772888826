import passiveEvent from "../../base/util/dom/passiveEvent";

const FooterToTopClassShow = 'firb__footer__to-top--show';

const toTopButton = document.querySelector('.firb__footer__to-top');
let lastScrollPosition = -1;

function updateToTopButton() {
    if(!toTopButton) {
        return;
    }

    const currentScrollPosition = Math.round(window.scrollY);
    
    if(lastScrollPosition !== -1) {
        if(lastScrollPosition === currentScrollPosition) {
            return;
        }

        toTopButton.classList.toggle(
            FooterToTopClassShow,
            lastScrollPosition > currentScrollPosition && currentScrollPosition > 100
        );
    }

    lastScrollPosition = currentScrollPosition;
}

function scrollToTop() {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
    });
}

toTopButton && toTopButton.addEventListener('click', scrollToTop, false);
window.addEventListener('scroll', updateToTopButton, passiveEvent);
updateToTopButton();