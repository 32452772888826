import onDOMContentLoaded from '../../base/util/dom/onDOMContentLoaded';
import animateHeightTransition from "../../base/util/dom/animateHeightTransition";
import BaseAccordion, {BaseAccordionUpdateItemEvent} from '../../components/base-accordion/base-accordion';

onDOMContentLoaded(() => {
    const options = {
        selectors: {
            accordion: '.firb__m07-accordion',
            toggle: '.as__accordion__toggle',
        },
        itemFactory: (toggle : HTMLInputElement) => {
            const trigger = toggle.nextElementSibling;
            return {
                toggle: toggle,
                triggers: trigger ? [trigger] : [],
                contents: trigger && trigger.nextElementSibling ? [trigger.nextElementSibling] : [],
                open: toggle.checked
            };
        }
    };

    document.querySelectorAll(options.selectors.accordion).forEach((root) => {
        if (!(root instanceof HTMLElement)) {
            return;
        }

        const accordion = new BaseAccordion(root, options);

        accordion.addEventListener('updateItem', (event : BaseAccordionUpdateItemEvent) => {
            event.item.contents.forEach(content => {
                animateHeightTransition(content, event.item.open);
            });
        });
    });

    let accordionToggles = document.querySelectorAll('button[toggle-accordion-with-id]');

    accordionToggles.forEach((toggle) => {
        if (!(toggle instanceof HTMLElement)) {
            return;
        }

        toggle.addEventListener("click", () => {
            let accordionId = toggle.getAttribute('toggle-accordion-with-id');
            let item = document.querySelector(`#${accordionId}`);

            if (!(item instanceof HTMLInputElement)) {
                return;
            }

            item.parentElement?.scrollIntoView({
                behavior: "smooth",
                block: "nearest"
            });

            item.click();
        });
    });
});