import passiveEvent from "./util/dom/passiveEvent";

document.addEventListener('DOMContentLoaded', () => {
    const noJsElement = document.querySelector('.as__no-js');

    if(noJsElement) {
        noJsElement.classList.remove('as__no-js');
    }
});

const ScrollTriggerOffset = 30;

window.addEventListener('scroll', updateHasScrolledClass, passiveEvent);
updateHasScrolledClass();

function updateHasScrolledClass() {
    document.body.classList.toggle('as__has-scrolled', window.pageYOffset > ScrollTriggerOffset);
}
