import EventTarget from "./dom/EventTarget";
import Log from "./Log";

const temporaryDiv = document.createElement('div');

export default abstract class AbstractComponent<O, E> extends EventTarget {
    protected readonly elements : E & { container: HTMLElement };
    protected readonly options : O;

    constructor(element : HTMLElement, options : O) {
        super();
        this.options = options;
        this.elements = {
            container: element
        } as any;
    }

    protected getTemplate(selector : string): HTMLTemplateElement {
        const result = this.queryRequiredSelector(selector, document);

        if(!('HTMLTemplateElement' in window)) {
            Log.warn('HTMLTemplateElement not supported?');
            return result as HTMLTemplateElement;
        }

        if(!(result instanceof HTMLTemplateElement)) {
            throw new Error('Could not find template element for selector "' + selector + '"');
        }

        return result;
    }

    protected queryRequiredSelector<H extends Element>(
        selector : string,
        element : Element|Document = this.elements.container
    ): H {
        const result = element.querySelector(selector);

        if(!(result instanceof Element)) {
            throw new Error('Could not find search element with selector "' + selector + '"');
        }

        return result as H;
    }

    protected querySelectorAll<H extends Element>(
        selector : string,
        element : Element|Document = this.elements.container
    ): Array<H> {
        return Array.prototype.slice.call(element.querySelectorAll(selector)).filter(element => {
            return element instanceof Element;
        }) as Array<H>;
    }

    protected getRequiredAttribute(attributeName : string, element : HTMLElement = this.elements.container): string {
        const value = element.getAttribute(attributeName);

        if(!value) {
            throw new Error('Required attribute "' + attributeName + '" not found on element ');
        }

        return value;
    }
 
    protected getDocumentFragmentFromHTML(htmlString : string): DocumentFragment {
        const fragment = document.createDocumentFragment();
        temporaryDiv.innerHTML = htmlString;

        while(temporaryDiv.firstChild) {
            fragment.appendChild(temporaryDiv.firstChild);
        }

        return fragment;
    };
}