loadSvgTemplates();

export default function loadSvgTemplates() {
    importSvgTemplate('/static/template/asset/icons.svg');
    importSvgTemplate('/static/template/asset/body_backgrounds.svg');
}

export function importSvgTemplate(url : string) {
    const xhr = new XMLHttpRequest();

    xhr.open('GET', url, true);
    xhr.addEventListener('load', () => {
        const div = document.createElement('div');

        div.className = 'as__icon__template';
        div.innerHTML = xhr.responseText;
        document.body.appendChild(div);
    });
    xhr.send();
}

export function createIcon(icon : string, iconClass : string|undefined|false = undefined, href ?: string): SVGSVGElement {
    const element = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    const use = document.createElementNS('http://www.w3.org/2000/svg', 'use');

    if(iconClass !== false) {
        element.classList.add('as__icon', iconClass || ('as__icon__' + icon));
    }

    use.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', href || ('#as__icon__' + icon));
    element.setAttribute('role', 'img');
    element.appendChild(use);

    return element;
}