// region IMPORTS
// base utilities
import assignOptions from '../../base/util/assignOptions';
import passiveEvent from '../../base/util/dom/passiveEvent';
import {DeepPartial} from '../../base/util/deepPartial';
import EventTarget, {createCustomEvent} from '../../base/util/dom/EventTarget';
// endregion IMPORTS

// region INTERFACES
export interface BaseAccordionSelectors {
    accordion: string,
    toggle: string
}

export interface BaseAccordionAttributes {
    allowMultiple: string,
}

export interface BaseAccordionOptions {
    selectors: BaseAccordionSelectors,
    attributes: BaseAccordionAttributes,
    itemFactory: (toggle : HTMLInputElement) => BaseAccordionItem|null
}

export interface BaseAccordionItem {
    toggle: HTMLInputElement,
    triggers: Array<HTMLElement>,
    contents: Array<HTMLElement>,
    open: boolean
}

export interface BaseAccordionChangeEvent extends Event {
    type: 'change',
    target: BaseAccordion,
    item: BaseAccordionItem
}

export interface BaseAccordionUpdateItemEvent extends Event {
    type: 'updateItem',
    target: BaseAccordion,
    item: BaseAccordionItem
}

export interface BaseAccordionCloseAllEvent extends Event {
    type: 'closeAll',
    target: BaseAccordion
}
 // endregion INTERFACES

export default class BaseAccordion extends EventTarget {
    public static readonly DefaultOptions : BaseAccordionOptions = {
        selectors: {
            accordion: '.as__accordion',
            toggle: '.as__accordion__toggle'
        },
        attributes: {
            allowMultiple: 'data-allow-multiple'
        },
        itemFactory: (toggle) => {
            return {
                toggle: toggle,
                triggers: [],
                contents: [],
                open: toggle.checked
            };
        }
    }

    protected options : BaseAccordionOptions;
    protected container : HTMLElement;
    protected items : Array<BaseAccordionItem>;
    protected itemOpenStateCache : Array<boolean>;
    protected readonly areMultipleItemsAllowed : boolean;

    constructor(
        element : HTMLElement,
        options : DeepPartial<BaseAccordionOptions> = {}
    ) {
        super();

        this.options = assignOptions(options, BaseAccordion.DefaultOptions);

        this.container = element;
        this.items = [];
        this.itemOpenStateCache = [];
        this.areMultipleItemsAllowed = element.hasAttribute(this.options.attributes.allowMultiple);

        element.querySelectorAll(this.options.selectors.toggle).forEach((toggleElement) => {
            if(!(toggleElement instanceof HTMLInputElement)) {
                return;
            }

            const item = this.options.itemFactory(toggleElement);

            if(!item) {
                return;
            }

            toggleElement.addEventListener('change', this.onChange.bind(this, item), passiveEvent);

            this.items.push(item);
            requestAnimationFrame(() => this.toggleItem(item, item.open));
        });
    }

    /**
     * Get current options
     */
    public getOptions() : BaseAccordionOptions {
        return this.options;
    }

    public getContainer() : HTMLElement {
        return this.container;
    }

    public getItems() : Readonly<Array<BaseAccordionItem>> {
        return this.items;
    }

    /**
     * Wrapper function for input change related functions
     *
     */
    protected onChange(item : BaseAccordionItem) : void {
        item.open = item.toggle.checked;

        if (!this.areMultipleItemsAllowed) {
            this.items.forEach((resetItem) => {
                if (resetItem.toggle === item.toggle) {
                    return;
                }

                resetItem.open = false;
            });
        }

        this.dispatchEvent(createCustomEvent('change', {
            item: item
        }));

        this.items.forEach(item => this.toggleItem(item, item.open));
    }

    protected toggleItem(item : BaseAccordionItem, openFlag : boolean) {
        const itemIndex = this.items.indexOf(item);

        if(itemIndex === -1) {
            return;
        }

        const lastState = this.itemOpenStateCache[itemIndex];

        item.open = openFlag;
        item.toggle.checked = openFlag;
        this.itemOpenStateCache[itemIndex] = openFlag;

        if(lastState === openFlag) {
            return;
        }

        item.triggers.forEach(trigger => {
            trigger.setAttribute('aria-expanded', String(openFlag));
        });

        this.dispatchEvent(createCustomEvent('updateItem', {
            item: item
        }))
    }

    /**
     * Reset all accordion input elements
     *
     */
    public closeAll() : void {
        if(!this.dispatchEvent(createCustomEvent('closeAll'))) {
            return;
        }

        this.items.forEach((item) => this.toggleItem(item, false));
    }
}
