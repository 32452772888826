// region IMPORTS
import Breakpoints from "../../settings/breakpoints";
// base utilities
import {DeepPartial} from "../../base/util/deepPartial";
import assignOptions from "../../base/util/assignOptions";
import BodyNoScroll from '../../base/util/dom/BodyNoScroll';
import onDOMContentLoaded from "../../base/util/dom/onDOMContentLoaded";
import passiveEvent from "../../base/util/dom/passiveEvent";
import animateHeightTransition from "../../base/util/dom/animateHeightTransition";
// components
import BaseAccordion, {BaseAccordionChangeEvent} from "../../components/base-accordion/base-accordion";
// sub modules
import HeaderNav, {HeaderNavOptions} from "./header-nav";
import HeaderSearch, {HeaderSearchOptions} from "./header-search";
// endregion IMPORTS

// region INTERFACES
export interface HeaderOptions {
    noScrollIdentifier: string,

    selectors: {
        header: string,
        toggle: string
        nav: string,
        navWrapper: string,
        accountToggle: string,
        accountDropdown: string,
    },
    
    classes: {
        accountToggleActive: string
    },

    nav: HeaderNavOptions,
    search: HeaderSearchOptions,
    navWrapperMobileOffset: number
}

export const HeaderMobileHeight = 121;

// endregion INTERFACES
export default class Header {
    public static readonly DefaultOptions : HeaderOptions = {
        noScrollIdentifier: 'as__header',

        selectors: {
            header: '.as__header',
            toggle: '.as__header__nav-icon-toggle',
            nav: '.as__header__nav-menu',
            navWrapper: '.as__header__nav-menu-wrapper',
            accountToggle: '.as__header__meta-link--account',
            accountDropdown: '.as__header__account',
        },

        classes: {
            accountToggleActive: 'as__header__meta-link--active'
        },

        nav: HeaderNav.DefaultOptions,
        search: HeaderSearch.DefaultOptions,

        navWrapperMobileOffset: HeaderMobileHeight
    };

    private readonly options : HeaderOptions;
    private readonly element : HTMLElement;
    private readonly headerAccordion : BaseAccordion;
    private readonly nav : HeaderNav;
    private readonly accountToggle: undefined|HTMLElement;
    private readonly accountDropdown: undefined|HTMLElement;
    private readonly navWrapper : HTMLElement;
    private readonly headerSearch : HeaderSearch;

    constructor(element : HTMLElement, options : DeepPartial<HeaderOptions> = {}) {
        this.element = element;
        this.options = assignOptions(options, Header.DefaultOptions);

        this.nav = new HeaderNav(this.querySelector(this.options.selectors.nav), this.options.nav);
        this.navWrapper = this.querySelector(this.options.selectors.navWrapper);
        this.headerAccordion = this.prepareHeaderAccordion();
        this.headerSearch = new HeaderSearch(
            this.querySelector(this.options.search.selectors.container),
            this.options.search
        );

        // seminar-shop: avoid TypeError if account not rendered in templates
        let body = document.body;
        if (body && !body.classList.contains('seminar-shop')) {
            this.accountToggle = this.querySelector(this.options.selectors.accountToggle);
            this.accountDropdown = this.querySelector(this.options.selectors.accountDropdown);
            this.accountToggle.addEventListener('click', this.onAccountToggleClick.bind(this), false);
            this.querySelector(this.options.selectors.toggle).addEventListener('click', this.onHamburgerClick.bind(this), false);
        }

        document.addEventListener('click', this.onDocumentClick.bind(this), passiveEvent);
        this.headerSearch.addEventListener('open-mobile', () => {
            this.nav.close();
            this.headerAccordion.closeAll();
        });
    }

    getElement() : HTMLElement {
        return this.headerAccordion.getContainer();
    }

    private onHamburgerClick(event : MouseEvent) : void {
        if(!(event.target instanceof HTMLElement)) {
            return;
        }

        this.navWrapper.classList.toggle('as__header__nav-menu-wrapper--show');
    }

    private onDocumentClick(event : MouseEvent) : void {
        if(!(event.target instanceof HTMLElement)) {
            return;
        }

        if(this.isOutsideNav(event.target)) {
            this.nav.close();
        }

        if((this.accountToggle instanceof HTMLElement) && this.isOutsideAccount(event.target)) {
            this.accountToggle.classList.remove(this.options.classes.accountToggleActive);
        }
    }
 
    private isOutsideNav(element : HTMLElement): boolean {
        return !element.closest(this.options.selectors.nav);
    }
    
    private isOutsideAccount(element : HTMLElement): boolean {
        return !element.closest(this.options.selectors.accountDropdown);
    }

    private onAccountToggleClick(event : MouseEvent): void {
        event.preventDefault();
        event.stopImmediatePropagation();

        if (this.accountToggle instanceof HTMLElement) {
            this.accountToggle.classList.toggle(this.options.classes.accountToggleActive);
        }
    }

    private prepareHeaderAccordion() : BaseAccordion {
        const headerAccordion = new BaseAccordion(this.element, {
            selectors: {
                toggle: this.options.selectors.toggle
            }
        });

        headerAccordion.addEventListener('change', (event : BaseAccordionChangeEvent) => {
            animateHeightTransition(
                this.navWrapper,
                event.item.open,
                window.innerHeight - this.options.navWrapperMobileOffset
            );

            BodyNoScroll.toggleNoScroll(this.options.noScrollIdentifier, event.item.open);
        });

        Breakpoints.addEventListener('resize-mobile-desktop', () => {
            headerAccordion.closeAll();
            BodyNoScroll.toggleNoScroll(this.options.noScrollIdentifier, false);
        });
        return headerAccordion;
    }

    private querySelector(selector : string): HTMLElement {
        const element = this.element.querySelector(selector);

        if(!(element instanceof HTMLElement)) {
            throw new Error('could not find header element with selector "' + selector + '"');
        }

        return element;
    }
}

onDOMContentLoaded(() => {
    document.querySelectorAll(Header.DefaultOptions.selectors.header).forEach((headerElement, index) => {
        if(!(headerElement instanceof HTMLElement)) {
            return;
        }

        if(headerElement.classList.contains('firb__header-reduced')) {
            return;
        }

        new Header(headerElement, {
            noScrollIdentifier: Header.DefaultOptions.noScrollIdentifier + '_' + index
        });
    });
});
