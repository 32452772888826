export default function animateHeightTransition(
    element : HTMLElement,
    open : boolean,
    maxHeight : number|string = element.scrollHeight,
    callback : () => void = () => {}
) : void {
    const computedStyles = window.getComputedStyle(element);
    const overflow = computedStyles.getPropertyValue('overflow');
    const transitionDurationString = computedStyles.getPropertyValue('transition-duration').match(/0\.(\d+)/);
    let transitionDuration = 0;

    if (transitionDurationString) {
        transitionDuration = parseInt(transitionDurationString[1], 10) * 100;
    }

    element.style.maxHeight = typeof maxHeight === 'string' ? maxHeight : (maxHeight + 'px');
    element.style.overflow = 'hidden';

    if (open) {
        setTimeout(() => {
            element.style.maxHeight = 'none';
            element.style.overflow = overflow;
            callback();
        }, transitionDuration);
    } else {
        setTimeout(() => {
            element.style.removeProperty('max-height');
        });

        setTimeout(() => {
            element.style.overflow = overflow;
            callback();
        }, transitionDuration);
    }
}