import onDOMContentLoaded from '../../base/util/dom/onDOMContentLoaded';

export default class SubNav {
    public static readonly Selectors = {
        root: ".firb__sub-nav__select"
    };

    private readonly root: HTMLSelectElement;

    constructor(element: HTMLSelectElement) {
        this.root = element;
        this.root.addEventListener('change', this.navigate.bind(this), false);
    }

    private navigate(event : Event): void {
        const option = this.root[this.root.selectedIndex];
        const link = option.getAttribute('data-link');

        if(!link) {
            return;
        }

        window.location.href = link;
    }
}

onDOMContentLoaded(() => {
    document.querySelectorAll(SubNav.Selectors.root).forEach(subNavSelect => {
        if(!(subNavSelect instanceof HTMLSelectElement)) {
            return;
        }
        
        new SubNav(subNavSelect);
    });
});
