import onDOMContentLoaded from '../../../base/util/dom/onDOMContentLoaded';
import {addResetFormErrorsEventListener} from '../../../base/util/formErrorsHelpers';

const showErrorInfo = (element: HTMLElement) => {
    const wrapper = <HTMLElement>element.closest('div');
    const info = wrapper.querySelector('.as__info-error');
    if (info) {
        info.classList.remove('as__hide');
    }
};

onDOMContentLoaded(() => {
    const root = document.querySelector('.firb__account-send_password_recovery_mail');
    if (!root) {
        return;
    }
    const button = <HTMLButtonElement>root.querySelector('button[name="_submit"]');
    const form = <HTMLFormElement>root.querySelector('form');

    button.addEventListener('click', () => {
        const element = <HTMLInputElement>form.elements.namedItem('email');
        if (element && !element.checkValidity()) {
            element.classList.add('as__field-error');
            showErrorInfo(element);
        } else {
            form.submit();
        }
    });
});