import LRUCache from "lru-cache";
import Breakpoints from "../../settings/breakpoints";
import {DeepPartial} from "../../base/util/deepPartial";
import assignOptions from "../../base/util/assignOptions";
import {createCustomEvent} from "../../base/util/dom/EventTarget";
import AbstractTemplatingComponent from "../../base/util/AbstractTemplatingComponent";
import passiveEvent from "../../base/util/dom/passiveEvent";
import BodyNoScroll from "../../base/util/dom/BodyNoScroll";

export interface HeaderSearchOptions {
    noScrollIdentifier: string,
    searchContainerOffsetTop: number,
    searchContainerOffsetBottom: number,

    selectors: {
        container: string,
        mobileToggle: string,
        input: string,
        resetButton: string,
        allButtons: string,
        searchContainer: string,
        scrollContainer: string,
        suggestContainer: string,
        suggestItem: string,
        resultContainer: string,
        resultItem: string
    },

    classes: {
        loading: string,
        searchContainerActive: string,
        scrollContainerOverflow: string
    },

    attributes: {
        apiUrl: string,
        searchUrl: string,
        markupTag: string
    },
    
    templates: {
        suggest: string,
        result: string,
        resultItem: string
    },
    
    slots: {
        suggestText: string,
        resultEntityName: string,
        resultItems: string,
        resultItemTitle: string,
        resultItemText: string,
        resultItemInfo: string
    }

    urlQueryParameter: string
}

export interface SearchResultSuggestion {
    text: string
}

export interface SearchResultEntityResultItem {
    title: string,
    text: string,
    info: string,
    link: string
}

export interface SearchResultEntityResult {
    entityName: string,
    items: Array<SearchResultEntityResultItem>
}

export interface SearchResult {
    suggestions: Array<SearchResultSuggestion>,
    results: Array<SearchResultEntityResult>
}

export interface HeaderSearchElements {
    container: HTMLElement,
    mobileToggle: HTMLInputElement,
    input: HTMLInputElement,
    resetButton: HTMLButtonElement,
    allButtons: Array<HTMLButtonElement>,
    searchContainer: HTMLElement,
    scrollContainer: HTMLElement
    suggestContainer: HTMLElement,
    resultContainer: HTMLElement
}

export default class HeaderSearch extends AbstractTemplatingComponent<HeaderSearchOptions, HeaderSearchElements> {
    public static readonly DefaultOptions : HeaderSearchOptions = {
        noScrollIdentifier: 'as__header__search',
        searchContainerOffsetTop: 151,
        searchContainerOffsetBottom: 40,

        selectors: {
            container: '.as__header__search',
            mobileToggle: '.as__header__search-toggle',
            input: '.as__header__search-input',
            resetButton: '.as__header__search-reset',
            allButtons: '.as__header__search-btn, .as__header__search-all-btn',
            searchContainer: '.as__header__search-container',
            scrollContainer: '.as__header__search-scroll-container',
            suggestContainer: '.as__header__search-suggest',
            suggestItem: '.as__header__search-suggest-item',
            resultContainer: '.as__header__search-results',
            resultItem: '.as__header__search-result-item'
        },
        
        classes: {
            loading: 'as__header__search--loading',
            searchContainerActive: 'as__header__search-container--active',
            scrollContainerOverflow: 'as__header__search-scroll-container--overflow'
        },

        attributes: {
            apiUrl: 'data-api-url',
            searchUrl: 'data-search-url',
            markupTag: 'data-markup-tag'
        },

        templates: {
            suggest: '#as__header__search-suggest-template',
            result: '#as__header__search-result-template',
            resultItem: '#as__header__search-result-item-template'
        },

        slots: {
            suggestText: 'text',
            resultEntityName: 'entityName',
            resultItems: 'items',
            resultItemTitle: 'title',
            resultItemText: 'text',
            resultItemInfo: 'info'
        },

        urlQueryParameter: '%QUERY%'
    };

    private readonly searchResultCache : LRUCache<string, SearchResult>;
    private readonly apiUrl : string;
    private readonly searchUrl : string;
    private readonly markupTag : string;
    private readonly markupTagRegExp : RegExp;
    private readonly templates : {
        suggest: HTMLTemplateElement,
        result: HTMLTemplateElement,
        resultItem: HTMLTemplateElement
    };
    private currentRequest : XMLHttpRequest | null;

    constructor(element : HTMLElement, options : DeepPartial<HeaderSearchOptions>) {
        super(element, assignOptions(options, HeaderSearch.DefaultOptions));

        this.searchResultCache = new LRUCache({
            max: 500,
            ttl: 10 * 60 * 1000 // 10 minutes
        });

        this.elements.mobileToggle = this.queryRequiredSelector(this.options.selectors.mobileToggle);
        this.elements.input = this.queryRequiredSelector(this.options.selectors.input);
        this.elements.resetButton = this.queryRequiredSelector(this.options.selectors.resetButton);
        this.elements.allButtons = this.querySelectorAll(this.options.selectors.allButtons);
        this.elements.searchContainer = this.queryRequiredSelector(this.options.selectors.searchContainer);
        this.elements.scrollContainer = this.queryRequiredSelector(this.options.selectors.scrollContainer);
        this.elements.suggestContainer = this.queryRequiredSelector(this.options.selectors.suggestContainer);
        this.elements.resultContainer = this.queryRequiredSelector(this.options.selectors.resultContainer);

        this.apiUrl = this.getRequiredAttribute(this.options.attributes.apiUrl);
        this.searchUrl = this.getRequiredAttribute(this.options.attributes.searchUrl);
        this.markupTag = this.getRequiredAttribute(this.options.attributes.markupTag);
        this.markupTagRegExp = new RegExp('<\/* *' + this.markupTag + ' *>', 'g');
        this.currentRequest = null;

        this.templates = {
            suggest: this.getTemplate(this.options.templates.suggest),
            result: this.getTemplate(this.options.templates.result),
            resultItem: this.getTemplate(this.options.templates.resultItem)
        };

        this.elements.mobileToggle.addEventListener('click', this.onMobileToggle.bind(this), false);
        this.elements.input.addEventListener('input', this.search.bind(this), passiveEvent);
        this.elements.input.addEventListener('focus', this.onFocus.bind(this), false);
        this.elements.resetButton.addEventListener('click', this.setSearch.bind(this, '', true), false);
        this.elements.allButtons.forEach(btn => btn.addEventListener('click', this.onAllButton.bind(this), false));
        this.elements.scrollContainer.addEventListener('scroll', this.checkScrollContainerSizing.bind(this), passiveEvent);
        document.addEventListener('click', this.onDocumentClick.bind(this), passiveEvent);
        window.addEventListener('resize', this.onResize.bind(this), passiveEvent);
        Breakpoints.addEventListener('resize-mobile-desktop', this.onClose.bind(this));

        this.onResize();
    }

    private async search() {
        const searchResult = await this.executeSearch();

        this.dispatchEvent(createCustomEvent('search-results', {
            detail: searchResult
        }));

        let renderedResults = this.renderResults(searchResult.results);
        let renderedSuggestions = this.renderSuggestions(searchResult.suggestions);

        this.elements.searchContainer.classList.toggle(
            this.options.classes.searchContainerActive,
            renderedResults || renderedSuggestions
        );
        this.elements.container.classList.remove(this.options.classes.loading);

        // next tick, so scrollContainer is rendered correctly
        requestAnimationFrame(() => this.checkScrollContainerSizing());
    }

    private renderResults(results : Array<SearchResultEntityResult>): boolean {
        let rendered : boolean = false;
        this.elements.resultContainer.innerHTML = '';

        for(let entityResult of results) {
            if(entityResult.items.length === 0) {
                continue;
            }

            const values = Object.assign({}, entityResult, {
                items: entityResult.items.map(this.renderResultItem.bind(this))
            }); 

            this.elements.resultContainer.appendChild(this.renderTemplate(this.templates.result, values));
            rendered = true;
        }

        return rendered;
    }

    private renderResultItem(resultItem : SearchResultEntityResultItem): DocumentFragment {
        const fragment = this.renderTemplate(this.templates.resultItem, resultItem, true);
        const link = fragment.querySelector(this.options.selectors.resultItem);

        if(link instanceof HTMLAnchorElement) {
            link.href = resultItem.link;
        }

        return fragment;
    }

    private renderSuggestions(suggestions : Array<SearchResultSuggestion>): boolean {
        this.elements.suggestContainer.innerHTML = '';

        for(let suggestion of suggestions) {
            this.elements.suggestContainer.appendChild(this.renderSuggestItem(suggestion));
        }

        return suggestions.length > 0;
    }

    private renderSuggestItem(suggestion : SearchResultSuggestion): DocumentFragment {
        const fragment = this.renderTemplate(this.templates.suggest, suggestion, true);
        const element = fragment.querySelector(this.options.selectors.suggestItem);

        if(element instanceof HTMLElement) {
            const searchQuery = suggestion.text.replace(this.markupTagRegExp, '');
            element.addEventListener('click', this.setSearch.bind(this, searchQuery, true), false);
        }

        return fragment;
    }

    private executeSearch(): Promise<SearchResult> {
        const query = this.elements.input.value;

        if(this.currentRequest) {
            this.currentRequest.abort();
            this.currentRequest = null;
        }

        if(!query) {
            return Promise.resolve({
                results: [],
                suggestions: []
            });
        }

        const cachedResult = this.searchResultCache.get(query);

        if(cachedResult) {
            return Promise.resolve(cachedResult);
        }

        // for loading animations
        this.elements.container.classList.add(this.options.classes.loading);

        const apiUrl = this.apiUrl.replace(this.options.urlQueryParameter, query);
        const xhr = new XMLHttpRequest();
        this.currentRequest = xhr;

        return new Promise(resolve => {
            xhr.open('GET', apiUrl, true);

            xhr.addEventListener('load', () => {
                if(this.currentRequest !== xhr) {
                    return;
                }

                if(xhr.status >= 200 && xhr.status < 400) {
                    const searchResult = JSON.parse(xhr.responseText);
                    this.searchResultCache.set(query, searchResult);
                    resolve(searchResult);
                }
            });

            xhr.send();
        });
    }

    private setSearch(searchString : string, focus : boolean): void {
        this.elements.input.value = searchString;

        if(focus) {
            this.focusInput(searchString.length, searchString.length);
        }

        this.search();
    }

    private checkScrollContainerSizing(): void {
        const container = this.elements.scrollContainer;

        container.classList.toggle(
            this.options.classes.scrollContainerOverflow,
            (container.scrollTop + container.clientHeight) < container.scrollHeight
        );
    }
    
    private updateDesktopSearchContainerHeight(): void {
        if(!Breakpoints.isDesktop()) {
            delete this.elements.searchContainer.style.maxHeight;
            return;
        }

        this.elements.searchContainer.style.maxHeight = (
            window.innerHeight
            - this.options.searchContainerOffsetTop
            - this.options.searchContainerOffsetBottom
        ) + 'px';
    }

    private onResize(): void {
        this.checkScrollContainerSizing();
        this.updateDesktopSearchContainerHeight();
    }

    private onDocumentClick(ev : Event): void {
        if(!Breakpoints.isDesktop() || !(ev.target instanceof HTMLElement)) {
            return;
        }

        if(!ev.target.closest(this.options.selectors.container)) {
            this.elements.searchContainer.classList.remove(this.options.classes.searchContainerActive);
        }
    }

    private onFocus(): void {
        if(!Breakpoints.isDesktop() || !this.elements.input.value) {
            return;
        }

        this.search();
    }

    private onAllButton(): void {
        window.location.href = this.searchUrl.replace(this.options.urlQueryParameter, this.elements.input.value);
    }

    private onMobileToggle(): void {
        if(this.elements.mobileToggle.checked) {
            BodyNoScroll.addNoScroll(this.options.noScrollIdentifier);
            this.dispatchEvent(createCustomEvent('open-mobile'));
            this.focusInput(0);
            this.checkScrollContainerSizing();
        } else {
            this.onClose();
        }
    }

    private onClose(): void {
        BodyNoScroll.removeNoScroll(this.options.noScrollIdentifier);
        this.setSearch('', false);
    }

    private focusInput(
        start : number = this.elements.input.value.length,
        end : number = this.elements.input.value.length
    ): void {
        this.elements.input.focus();
        this.elements.input.setSelectionRange(start, end);
    }
}