export interface LogInterface {
    log: (...args : Array<any>) => void;
    warn: (...args : Array<any>) => void;
    error: (...args : Array<any>) => void;
}

const Log : LogInterface = {
    log: () => {},
    warn: () => {},
    error: () => {}
};

if('console' in window) {
    if(typeof console.log === 'function') {
        Log.error = Log.warn = Log.log = console.log.bind(console);
    }

    if(typeof console.warn === 'function') {
        Log.error = Log.warn = console.warn.bind(console);
    }

    if(typeof console.error === 'function') {
        Log.error = console.error.bind(console);
    }
}

export default Log;