import isRtl from "../../base/util/dom/isRtl";
import {createCustomEvent} from '../../base/util/dom/EventTarget';
import BaseSlider from "./base-slider";

export interface BaseSliderItemGridSizes {
    [breakpoint : string]: number
}

export type BaseSliderItemTrueCloneType = "prepended"|"appended";
export type BaseSliderItemCloneType = false|BaseSliderItemTrueCloneType;

export default class BaseSliderItem {
    public static readonly OutsidePositionMultiplier = 1.1;
    public static readonly ItemIdAttribute = "data-slider-item-id";

    protected readonly slider : BaseSlider;
    protected readonly element : HTMLElement;
    protected readonly id : string;
    protected readonly isClonedItem : BaseSliderItemCloneType;
    protected readonly gridSizes : BaseSliderItemGridSizes;
    protected readonly positioningFn : (element : HTMLElement, position : number, width : number) => void;

    protected transitionEnabled : boolean;
    protected rect : DOMRect | null;
    protected width : number;
    protected position : number;

    public constructor(element : HTMLElement, id : string, slider : BaseSlider, isClone : BaseSliderItemCloneType = false) {
        this.slider = slider;
        this.element = element;
        this.id = id;
        this.isClonedItem = isClone;

        // set slider item id as attribute for easier debugging
        this.element.setAttribute(BaseSliderItem.ItemIdAttribute, this.getId());

        const options = slider.getOptions();

        if(options.core.animationType === 'slide') {
            this.positioningFn = BaseSliderItem.setPositionSlide;
        } else {
            this.positioningFn = BaseSliderItem.setPositionFade;
        }

        if(isClone && options.infinite && options.infinite.clonedSlideClass) {
            this.element.classList.add(options.infinite.clonedSlideClass);
        }

        this.transitionEnabled = false;
        this.gridSizes = {};
        this.rect = null;
        this.width = 0;
        this.position = 0;

        this.initGrid();

        slider.addEventListener('resize', () => {
            this.rect = null;
        });
        
        slider.dispatchEvent(createCustomEvent('initialized-item', {item: this}))
    }

// region GLOBAL
    public getElement() : HTMLElement {
        return this.element;
    }

    public getId() : string {
        return this.id + (this.isClone() ? '_cloned' : '_real');
    }

    public getRawId() : string {
        return this.id.split("_")[0];
    }

    public setWidth(width : number): this {
        this.rect = null;
        this.width = Math.round(width);
        this.element.style.width = this.width + 'px';
        return this;
    }

    public getWidth() : number {
        return this.width;
    }
    
    public setHeight(height : number): this {
        this.rect = null;
        this.element.style.height = height + 'px';
        return this;
    }

    public getHeight() : number {
        if(!this.rect) {
            this.rect = this.element.getBoundingClientRect();
        }

        return this.rect.height;
    }

    public isClone() : boolean {
        return Boolean(this.isClonedItem);
    }

    public isClonePrepended() : boolean {
        return this.isClonedItem === "prepended";
    }

    public isCloneAppended() : boolean {
        return this.isClonedItem === "appended";
    }

    public getSizeRatio(breakpoint : string) : number {
        if(breakpoint in this.gridSizes) {
            return this.gridSizes[breakpoint];
        }

        // fallback to first registered breakpoint
        const keys = Object.keys(this.gridSizes);
        if(keys.length > 0) {
            return this.gridSizes[keys[0]];
        }

        // fallback to full width
        return 1;
    }

    public setTransition(enabled : boolean): this {
        if(enabled && !this.transitionEnabled) {
            this.element.style.transition = this.slider.getOptions().core.animationTransition;
        } else if(!enabled && this.transitionEnabled) {
            this.element.style.transition = 'none';
        }

        this.transitionEnabled = enabled;
        return this;
    }

    public setAbsolutePositioning(enabled : boolean): this {
        if(enabled) {
            this.element.style.position = 'absolute';
            this.setTransition(this.transitionEnabled);
        } else {
            this.element.style.removeProperty('width');
            this.element.style.removeProperty('position');
            this.element.style.removeProperty('transform');
            this.element.style.removeProperty('transition');
            this.element.style.removeProperty('opacity');
        }

        return this;
    }

    public setPosition(position : number, callback ?: () => void): this {
        this.position = Math.round(position * (isRtl() ? -1 : 1));
        this.positioningFn(this.element, this.position, this.width);

        if(callback) {
            let timeoutDelay = 0;

            if(this.transitionEnabled) {
                timeoutDelay = this.slider.getOptions().core.animationDuration;
            }

            setTimeout(callback, timeoutDelay);
        }

        return this;
    }

    public updateItemForPages(forceUpdate : boolean) : number {
        const sizeRatio = this.getSizeRatio(this.slider.getState().breakpoint);

        if(forceUpdate) {
            this.setAbsolutePositioning(true)
                .setWidth(this.slider.getState().width * sizeRatio);
        }

        return sizeRatio;
    }

    public clone(cloneType : BaseSliderItemTrueCloneType) : BaseSliderItem {
        return new BaseSliderItem(
            this.element.cloneNode(true) as HTMLElement,
            this.id + (cloneType === "prepended" ? "_P" : "_A"),
            this.slider,
            cloneType
        );
    }
// endregion GLOBAL

// region GRID
    protected initGrid() {
        const sliderOptions = this.slider.getOptions();
        const gridColumns = sliderOptions.grid.gridColumns;
        const breakpoints = sliderOptions.grid.breakpoints;
        const breakpointNamesOrdered = Object.keys(breakpoints).sort((aName, bName) => {
            return breakpoints[aName] - breakpoints[bName];
        });

        for(let i = 0; i < breakpointNamesOrdered.length; ++i) {
            const breakpointName = breakpointNamesOrdered[i];

            if(!breakpoints.hasOwnProperty(breakpointName)) {
                continue;
            }

            let gridSize = sliderOptions.grid.getColumnSize(this.element, breakpointName) / gridColumns;

            if(gridSize === 0) {
                if (i > 0) {
                    gridSize = this.gridSizes[breakpointNamesOrdered[i - 1]];
                } else {
                    gridSize = 1;
                }
            }

            this.gridSizes[breakpointName] = gridSize;
        }
    }
// endregion GRID

    protected static setPositionSlide(element : HTMLElement, position : number) {
        element.style.transform = 'translate3d(' + position + 'px, 0, 0)';
    }

    protected static setPositionFade(element : HTMLElement, position : number, width: number) {
        element.style.opacity = '' + Math.max(0, Math.min(1, 1 - Math.abs(position) / width));
    }
}
