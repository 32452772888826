// region IMPORTS
// components
import BaseAccordion, {
    BaseAccordionItem,
    BaseAccordionUpdateItemEvent
} from "../../components/base-accordion/base-accordion";
// endregion IMPORTS

// region INTERFACES
export interface HeaderNavOptions {
    selectors: {
        toggle: string,
        content: string,
        label: string,
        subNav: string
    }
}
// endregion INTERFACES

export default class HeaderNav {
    public static readonly DefaultOptions : HeaderNavOptions = {
        selectors: {
            toggle: ':scope > .as__header__nav-menu-item > .as__header__nav-item-toggle',
            content: '.as__header__nav-item-toggle[id=%ID%] ~ .as__header__nav-menu-children',
            label: '.as__header__nav-item-toggle[id=%ID%] ~ .as__header__nav-item-label',
            subNav: ':scope > .as__header__nav-menu--submenu'
        }
    };

    private readonly options : HeaderNavOptions;
    private readonly accordion : BaseAccordion;
    private readonly children : Map<BaseAccordionItem, HeaderNav>;

    constructor(element : HTMLElement, options : HeaderNavOptions) {
        this.options = options;

        this.accordion = new BaseAccordion(element, {
            selectors: {
                toggle: this.options.selectors.toggle
            },
            itemFactory: this.navItemFactory.bind(this, element)
        });

        this.accordion.addEventListener('updateItem', this.onNavUpdateItem.bind(this));

        this.children = new Map();

        this.accordion.getItems().forEach(item => {
            const content = item.contents[0];
            const childNav = content.querySelector(this.options.selectors.subNav);

            if(!(childNav instanceof HTMLUListElement)) {
                return;
            }

            this.children.set(item, new HeaderNav(childNav, this.options));
        });
    }

    getElement() : HTMLElement {
        return this.accordion.getContainer();
    }

    public close() {
        this.children.forEach((child) => child.close());
        this.accordion.closeAll();
    }

    private onNavUpdateItem(event : BaseAccordionUpdateItemEvent) : void {
        if(event.item.open) {
            return;
        }

        const childNav = this.children.get(event.item);

        if(!childNav) {
            return;
        }

        childNav.close();
    }

    private navItemFactory(wrapper : HTMLElement, toggle : HTMLInputElement) : BaseAccordionItem|null {
        const label = wrapper.querySelector(this.options.selectors.label.replace('%ID%', toggle.id));
        const content = wrapper.querySelector(this.options.selectors.content.replace('%ID%', toggle.id));

        if(!(label instanceof HTMLElement) || !(content instanceof HTMLElement)) {
            return null;
        }

        return {
            toggle: toggle,
            triggers: [label],
            contents: [content],
            open: toggle.checked
        };
    }
}
