import onDOMContentLoaded from "../../../base/util/dom/onDOMContentLoaded";
import Flyout from "../../cart/flyout";
import {ApiRequest, FormApiRequest, SimpleResponseHandler} from "../../../base/util/Api";

const Classes = {
    Buy: 'firb__product-buy',
    Quantity: 'firb__product-buy__quantity',
    IsLoading: 'firb__product-buy--is-loading'
}

onDOMContentLoaded(() => {
    document.querySelectorAll('.' + Classes.Buy).forEach(buyElement => {
        if(buyElement instanceof HTMLFormElement) {
            handleBuyForm(buyElement);
        } else {
            // TODO for buy links?
        }
    });
});

function handleBuyForm(buyForm : HTMLFormElement): void {
    const action = buyForm.getAttribute('action') || window.location.href;
    const method = buyForm.getAttribute('method') || 'POST';
    const quantitySelect = buyForm.querySelector('.' + Classes.Quantity);
    const request = new FormApiRequest(action, method, SimpleResponseHandler);

    buyForm.addEventListener('submit', (event : Event) => {
        event.preventDefault();

        const data = new FormData(buyForm);
        let amount : number = 1;

        if(quantitySelect instanceof HTMLSelectElement || quantitySelect instanceof HTMLInputElement) {
            amount = parseInt(quantitySelect.value, 10);

            if(amount <= 0) {
                return;
            }
        }

        handleBuyRequest(request.setFormData(data), buyForm, amount);
    }, false); 
}

function handleBuyRequest(request : ApiRequest<any, any, any>, element : HTMLElement, amount : number = 1): void {
    element.classList.add(Classes.IsLoading);

    request.send().then(() => {
        let msg : string;

        if(amount === 1) {
            msg = '1 Produkt wurde erfolgreich zum Warenkorb hinzugefügt.';
        } else {
            msg = amount + ' Produkte wurden erfolgreich zum Warenkorb hinzugefügt.';
        }

        Flyout.show().alert('success', msg).reload();
        element.classList.remove(Classes.IsLoading);
    }, err => {
        if(err instanceof DOMException && err.name === 'AbortError') {
            return;
        }

        element.classList.remove(Classes.IsLoading);

        if(element instanceof HTMLFormElement) {
            element.submit();
        } else {
            element.click();
        }
    });
}